import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Breadcrumbs, Button, Grid, Link as MuiLink, styled, TextField, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { SecuredLayout } from '../Layout/SecuredLayout';
import { useAuth, useProvideSnackBar } from '../../utils';
import { Link, useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import SpeedIcon from '@mui/icons-material/Speed';
import { Role } from '../../@types/auth';

const StyledGrid = styled(Grid)`
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export default function SpeedLimits() {
    const navigate = useNavigate();
    const { user } = useAuth();

    useEffect(() => {
        if (!(user?.hasRole(Role.FOVEZENYLO) || user?.hasRole(Role.ADMIN))) {
            navigate('/');
        }
    }, [user, navigate]);

    return (
        <SecuredLayout>
            <Grid container px={2}>
                <Grid container py={1} item xs={12} textAlign={'right'} sx={{ justifyContent: 'space-between' }}>
                    <Breadcrumbs aria-label='breadcrumb' sx={{ display: 'inline-block' }}>
                        <MuiLink
                            component={Link}
                            underline='hover'
                            sx={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}
                            color='inherit'
                            to='/planner'>
                            Tervező
                            <EditRoadIcon sx={{ ml: 0.5, mb: -0.125 }} fontSize={'small'} />
                        </MuiLink>
                    </Breadcrumbs>
                    <Breadcrumbs aria-label='breadcrumb' sx={{ display: 'inline-block' }}>
                        <MuiLink
                            component={Link}
                            underline='hover'
                            sx={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}
                            color='inherit'
                            to='/speed-limits'>
                            Sebességlimitek
                            <SpeedIcon sx={{ ml: 0.5, mb: -0.125 }} fontSize={'small'} />
                        </MuiLink>
                        <MuiLink
                            component={Link}
                            underline='hover'
                            sx={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}
                            color='inherit'
                            to='/schedule'>
                            Menetrend
                            <VisibilityIcon sx={{ ml: 0.5, mb: -0.125 }} fontSize={'small'} />
                        </MuiLink>
                    </Breadcrumbs>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <SpeedLimitEditor busType='large' label='Nagybusz' />
                    </Grid>
                    <Grid item xs={6}>
                        <SpeedLimitEditor busType='small' label='Kisbusz' />
                    </Grid>
                </Grid>
            </Grid>
        </SecuredLayout>
    );
}

function SpeedLimitEditor({ busType, label }: { busType: string; label: string }) {
    const { user } = useAuth();
    const { showResponseError, showSuccess } = useProvideSnackBar();

    const [motorway, setMotorway] = useState(0);
    const [trunk, setTrunk] = useState(0);
    const [primary, setPrimary] = useState(0);
    const [secondary, setSecondary] = useState(0);
    const [tertiary, setTertiary] = useState(0);
    const [unclassified, setUnclassified] = useState(0);
    const [residential, setResidential] = useState(0);
    const [residentialLink, setResidentialLink] = useState(0);
    const [motorwayLink, setMotorwayLink] = useState(0);
    const [trunkLink, setTrunkLink] = useState(0);
    const [primaryLink, setPrimaryLink] = useState(0);
    const [secondaryLink, setSecondaryLink] = useState(0);
    const [tertiaryLink, setTertiaryLink] = useState(0);
    const [livingStreet, setLivingStreet] = useState(0);
    const [service, setService] = useState(0);
    const [track, setTrack] = useState(0);
    const [busGuideway, setBusGuideway] = useState(0);
    const [road, setRoad] = useState(0);
    const [busway, setBusway] = useState(0);

    const fetchSpeedLimits = async () => {
        const response = await fetch(`/api/speed-limits?busType=${busType}`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.accessToken}`,
            },
        });

        if (!response.ok) {
            showResponseError(response);
            return;
        }

        const body = (await response.json()).SpeedLimit;

        setMotorway(body.Motorway);
        setTrunk(body.Trunk);
        setPrimary(body.Primary);
        setSecondary(body.Secondary);
        setTertiary(body.Tertiary);
        setUnclassified(body.Unclassified);
        setResidential(body.Residential);
        setResidentialLink(body.ResidentialLink);
        setMotorwayLink(body.MotorwayLink);
        setTrunkLink(body.TrunkLink);
        setPrimaryLink(body.PrimaryLink);
        setSecondaryLink(body.SecondaryLink);
        setTertiaryLink(body.TertiaryLink);
        setLivingStreet(body.LivingStreet);
        setService(body.Service);
        setTrack(body.Track);
        setBusGuideway(body.BusGuideway);
        setRoad(body.Road);
        setBusway(body.Busway);
    };

    const saveSpeedLimits = async () => {
        const response = await fetch(`/api/speed-limits`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.accessToken}`,
            },
            body: JSON.stringify({
                BusType: busType,
                Motorway: motorway,
                Trunk: trunk,
                Primary: primary,
                Secondary: secondary,
                Tertiary: tertiary,
                Unclassified: unclassified,
                Residential: residential,
                ResidentialLink: residentialLink,
                MotorwayLink: motorwayLink,
                TrunkLink: trunkLink,
                PrimaryLink: primaryLink,
                SecondaryLink: secondaryLink,
                TertiaryLink: tertiaryLink,
                LivingStreet: livingStreet,
                Service: service,
                Track: track,
                BusGuideway: busGuideway,
                Road: road,
                Busway: busway,
            }),
        });

        if (!response.ok) {
            showResponseError(response);
            return;
        }

        showSuccess('Sikeres mentés');
    };

    useEffect(() => {
        fetchSpeedLimits();
    }, []);

    return (
        <StyledGrid container p={2}>
            <Grid item xs={6}>
                <Typography style={{ marginBottom: '10px' }}>{label}</Typography>
            </Grid>

            <Grid item xs={6} alignSelf={'flex-end'} sx={{ textAlign: 'right' }}>
                <Button
                    variant='contained'
                    color='info'
                    title={'Mentés'}
                    onClick={() => saveSpeedLimits()}
                    size={'small'}
                    endIcon={<SaveIcon />}
                    sx={{ borderRadius: '50px' }}>
                    Mentés
                </Button>
            </Grid>

            <SpeedLimitEditorField label='motorway' value={motorway} setValue={setMotorway} />
            <SpeedLimitEditorField label='trunk' value={trunk} setValue={setTrunk} />
            <SpeedLimitEditorField label='primary' value={primary} setValue={setPrimary} />
            <SpeedLimitEditorField label='secondary' value={secondary} setValue={setSecondary} />
            <SpeedLimitEditorField label='tertiary' value={tertiary} setValue={setTertiary} />
            <SpeedLimitEditorField label='unclassified' value={unclassified} setValue={setUnclassified} />
            <SpeedLimitEditorField label='residential' value={residential} setValue={setResidential} />
            <SpeedLimitEditorField label='residentialLink' value={residentialLink} setValue={setResidentialLink} />
            <SpeedLimitEditorField label='motorwayLink' value={motorwayLink} setValue={setMotorwayLink} />
            <SpeedLimitEditorField label='trunkLink' value={trunkLink} setValue={setTrunkLink} />
            <SpeedLimitEditorField label='primaryLink' value={primaryLink} setValue={setPrimaryLink} />
            <SpeedLimitEditorField label='secondaryLink' value={secondaryLink} setValue={setSecondaryLink} />
            <SpeedLimitEditorField label='tertiaryLink' value={tertiaryLink} setValue={setTertiaryLink} />
            <SpeedLimitEditorField label='livingStreet' value={livingStreet} setValue={setLivingStreet} />
            <SpeedLimitEditorField label='service' value={service} setValue={setService} />
            <SpeedLimitEditorField label='track' value={track} setValue={setTrack} />
            <SpeedLimitEditorField label='busGuideway' value={busGuideway} setValue={setBusGuideway} />
            <SpeedLimitEditorField label='road' value={road} setValue={setRoad} />
            <SpeedLimitEditorField label='busway' value={busway} setValue={setBusway} />
        </StyledGrid>
    );
}

function SpeedLimitEditorField({ label, value, setValue }: { label: string; value: number; setValue: Dispatch<SetStateAction<number>> }) {
    return (
        <Grid item xs={6} sm={4} md={3} lg={2} paddingBottom={1}>
            <TextField label={label} variant='standard' value={value} onChange={e => setValue(parseInt(e.target.value) || 0)} />
        </Grid>
    );
}
