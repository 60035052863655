import React, { memo, useEffect, useMemo, useState } from 'react';
import { FormControl, IconButton, MenuItem, Select, Stack, Typography } from '@mui/material';
import { DirectionType, Passenger, Requirement, Stop } from '../../@types/requirements';
import { DailyPassengers, Line, LinesResponse, LineStop, SortableLineStop } from '../../@types/lines';
import { useAuth, useProvideSnackBar } from '../../utils';

import {
    DataGridPro,
    DataGridProProps,
    GRID_TREE_DATA_GROUPING_FIELD,
    GridColDef,
    GridPinnedRowsProp,
    GridRenderCellParams,
    GridRowsProp,
    GridValidRowModel,
    useGridApiRef,
} from '@mui/x-data-grid-pro';
import StopDndContext from './StopDndContext';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import StopListGridTreeDataGroupingCell from './StopListGridTreeDataGroupingCell';
import { useStopListExpandedGroupingRows } from '../../hooks/useStopListExpandedGroupingRows';
import ErrorBoundary from '../../hooks/errorBoundary';
import DeleteIcon from '@mui/icons-material/Delete';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import LineShiftUpdateTimePicker from './LineShiftUpdateTimePicker';
import LineStopTimePicker from './LineStopTimePicker';
import FactoryIcon from '@mui/icons-material/Factory';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import PassengerCheckboxesCell from './PassengerCheckboxesCell';
import { StopDeletabilityMap } from '../../@types/map';

const columns: GridColDef[] = [
    {
        field: 'OurReorderCell',
        headerName: ' ',
        flex: 1,
        width: 20,
        minWidth: 20,
        maxWidth: 20,
        disableColumnMenu: true,
        sortable: false,
        headerAlign: 'left',
        align: 'left',
        resizable: false,
        display: 'flex',
        renderCell: params => {
            if (params.row.type === 'PASSENGER' || !params.row.stop) {
                return null;
            }
            return <StopDndContext stop={params.row.stop} />;
        },
    },
    {
        field: GRID_TREE_DATA_GROUPING_FIELD,
    },
    {
        field: 'name',
        headerName: ' ',
        flex: 1,
        disableColumnMenu: true,
        sortable: false,
        headerAlign: 'left',
        align: 'left',
        resizable: false,
        renderCell: params => {
            if (params.row.id < 0 || params.row.isAllPassengerHeadquarter === undefined) {
                return params.row.name;
            }
            return (
                <Typography
                    m={0}
                    p={0}
                    paragraph
                    sx={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflowX: 'hidden',
                        maxWidth: '90%',
                        backgroundColor: !params.row.isAllPassengerHeadquarter ? 'yellow' : '',
                    }}
                    title={params.row.name}>
                    {params.row.name}
                </Typography>
            );
        },
    },
    {
        field: 'distance',
        headerName: 'Km',
        flex: 1,
        disableColumnMenu: true,
        sortable: false,
        headerAlign: 'left',
        align: 'left',
        resizable: false,
        maxWidth: 40,
        renderCell: params => {
            if (!params.row.distance) {
                return '';
            }
            return (
                <Typography
                    m={0}
                    p={0}
                    title={params.row.distance}
                    paragraph
                    sx={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflowX: 'hidden',
                    }}
                    >
                    {params.row.distance}
                </Typography>
            );
        },
    },
    {
        field: 'lineStopExchange',
        headerName: ' ',
        width: 55,
        minWidth: 55,
        maxWidth: 55,
        sortable: false,
        disableColumnMenu: true,
        headerAlign: 'center',
        align: 'center',
        resizable: false,
        renderCell: params => {
            if (!params.row.stop) {
                return '';
            }
            if (params.row.stop.Type === 'site') {
                return <FactoryIcon />;
            }
            return (
                <FormControl size={'small'}>
                    <Select
                        variant='standard'
                        value=''
                        sx={{ marginRight: '4px', '& .MuiSelect-select': { padding: 0 } }}
                        defaultValue=''
                        onChange={e => params.row.exchangeLineStopHandler(e.target.value, params.row.otherLines)}
                        displayEmpty
                        renderValue={value => <ImportExportIcon fontSize={'small'} sx={{ px: 0.5, pt: 0.5, pb: 0 }} />}>
                        {params.row.lineNames.map((lineName: string) => (
                            <MenuItem value={lineName} key={lineName}>
                                {lineName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );
        },
    },
    {
        field: 'lineStopPicker',
        headerName: 'Indulás',
        width: 50,
        minWidth: 50,
        maxWidth: 50,
        sortable: false,
        disableColumnMenu: true,
        headerAlign: 'center',
        align: 'center',
        resizable: false,
        renderCell: params => {
            if (!params.row.lines) {
                return null;
            }
            switch (params.row.id) {
                case -1:
                case -3:
                    return <LineShiftUpdateTimePicker lines={params.row.lines} field='ShiftDepartureAt' />;
                case -4:
                    return '';
                default: {
                    if (params.row.type !== 'PASSENGER' && params.row.stop) {
                        return (
                            <LineStopTimePicker
                                lines={params.row.lines}
                                lineStop={params.row.stop}
                                setLineStops={params.row.setLineStops}
                                sortStops={params.row.sortStops}
                            />
                        );
                    }
                    return '';
                }
            }
        },
    },
    {
        field: 'priorityHighIcon',
        headerName: ' ',
        width: 10,
        minWidth: 10,
        maxWidth: 10,
        sortable: false,
        disableColumnMenu: true,
        headerAlign: 'center',
        align: 'center',
        resizable: false,
        display: 'flex',
        renderCell: params => {
            if (params.row.isHighPriority) {
                return (
                    <PriorityHighIcon
                        sx={{
                            fontSize: 16,
                            color: 'red',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    />
                );
            }
            return '';
        },
    },
    {
        field: 'delete',
        headerName: ' ',
        width: 40,
        minWidth: 40,
        maxWidth: 40,
        sortable: false,
        disableColumnMenu: true,
        headerAlign: 'center',
        align: 'center',
        resizable: false,
        renderCell: params => {
            if (params.row.type === 'STOP') {
                if (params.row.stop.Type === 'site') {
                    return (
                        <IconButton onClick={() => params.row.deleteLineStopHandler(params.row.name)} sx={{ pointerEvents: 'auto', padding: '5px' }}>
                            <DeleteIcon sx={{ fontSize: 20 }} />
                        </IconButton>
                    );
                }

                if (params.row.stop.Type === 'stop') {
                    return (
                        <IconButton onClick={() => params.row.deleteLineStopHandler(params.row.name)} sx={{ pointerEvents: 'auto', padding: '5px' }}>
                            <DeleteIcon color={params.row.deleteIconColor} sx={{ fontSize: 20 }} />
                        </IconButton>
                    );
                }
            }

            if (params.row.type === 'PASSENGER') {
                return (
                    <IconButton onClick={() => params.row.deleteLineStopPassengerHandler(params.row.id)} sx={{ pointerEvents: 'auto', padding: '5px' }}>
                        <DeleteIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                );
            }
        },
    },
];

const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: ' ',
    width: 20,
    minWidth: 20,
    maxWidth: 20,
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    resizable: false,
    headerAlign: 'center',
    align: 'center',
    display: 'flex',
    renderCell: params => <StopListGridTreeDataGroupingCell {...params} />,
};

type LineStopListProps = {
    requirements: Requirement[];
    lines: Line[];
    lineStops: SortableLineStop[];
    afterChangeCallback: () => void;
    dailyPassengers: DailyPassengers;
    lineNames: string[];
    allLines: Line[];
    fetchRequirements: () => void;
    setLineStops: (stops: SortableLineStop[]) => void;
    direction: keyof typeof DirectionType;
    sortStops: (lines: Line[]) => SortableLineStop[];
};

const LineStopList = memo(
    ({
        requirements,
        lines,
        lineStops,
        afterChangeCallback,
        lineNames,
        allLines,
        fetchRequirements,
        setLineStops,
        direction,
        sortStops,
    }: LineStopListProps) => {
        const { showError, showResponseError } = useProvideSnackBar();
        const { user } = useAuth();
        const [rows, setRows] = useState<GridRowsProp>([]);
        const [activeStop, setActiveStop] = useState<SortableLineStop | null>(null);
        const { expandedRows } = useStopListExpandedGroupingRows();
        const [gridPinRows, setGridPinRows] = useState<GridPinnedRowsProp>();
        const apiRef = useGridApiRef();

        const handleDeleteLineStop = async (stopName: String) => {
            const response = await fetch('/api/linestop', {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    ID: lines
                        .flatMap(l => l.Stops)
                        .filter((lineStop: LineStop) => stopName === lineStop.Name)
                        .map((stop: LineStop) => stop.ID),
                }),
            });
            if (!response.ok) {
                showError(`${response.status}`);
                return;
            }
            afterChangeCallback();
        };

        const handleDeleteLineStopPassenger = async (workerID: number) => {
            const response = await fetch('/api/linestop/passenger', {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    LineIDs: lines.map(line => line.ID),
                    LineStopPassengerIDs: lines
                        .flatMap(line => line.Stops)
                        .flatMap(stop => stop.Passengers)
                        .filter(passenger => passenger.WorkerID === workerID)
                        .map(passenger => passenger.ID),
                }),
            });
            if (!response.ok) {
                showError(`${response.status}`);
                return;
            }
            afterChangeCallback();
        };

        const saveStopChange = async (updatedStops: LineStop[]) => {
            const response = await fetch('/api/linestop', {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    Stops: updatedStops.map(stop => {
                        return {
                            ID: stop.ID,
                            Position: stop.Position,
                        };
                    }),
                }),
            });
            if (!response.ok) {
                showResponseError(response);
            }
            const body: LinesResponse = await response.json();
            const respLines: Line[] = body.Lines.filter((line: Line) => line.Name === lines[0].Name);
            setLineStops(sortStops(respLines));
        };

        const updateExchangeFetch = async (lineStopID: LineStop[], targetLineIDs: number[]) => {
            try {
                const response = await fetch('/api/lines/exchange', {
                    method: 'PUT',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user?.accessToken}`,
                    },
                    body: JSON.stringify({
                        targetLineIDs: targetLineIDs,
                        lineStopIDs: lineStopID.map(stop => stop.ID),
                    }),
                });
                if (response.ok) {
                    afterChangeCallback();
                } else {
                    throw new Error('Failed to update data');
                }
            } catch (error: any) {
                showError(`Hiba történt: ${error.message}`);
            }
        };

        const handleLineStopExchange = async (lineName: string, lineStop: LineStop[]) => {
            const filteredLines = allLines.filter(line => line.Name === lineName).map(line => line.ID);

            try {
                updateExchangeFetch(lineStop, filteredLines);
            } catch (error: any) {
                showError(`Hiba történt a megálló áthelyezése közben: ${error.message}`);
            }
        };

        const createGridPinRows = (): GridPinnedRowsProp => {
            const pinnedRows: GridPinnedRowsProp = {
                top: [],
                bottom: [],
            };

            const initializePinnedRow = (id: number, name: string) => ({
                id: id,
                name: name,
                ...Object.fromEntries(requirements.map(r => ['requirement-' + r.DueAt, 0])),
            });

            const distanceTraveledRow: { [key: string]: any } = initializePinnedRow(-4, 'Megtett távolság');
            const roadFeeM2Row: { [key: string]: any } = initializePinnedRow(-5, 'Útdíj (M2 J2, E5)');
            const roadFeeM3J2Row: { [key: string]: any } = initializePinnedRow(-6, 'Útdíj (M3 J2, E5)');
            const roadFeeM3J3Row: { [key: string]: any } = initializePinnedRow(-7, 'Útdíj (M3 J3, E5)');

            requirements.forEach(r => {
                const line = lines.find(l => l.RequirementID === r.ID);
                if (line) {
                    distanceTraveledRow['requirement-' + r.DueAt] = Math.round(line.TravelDistance || 0);
                    roadFeeM2Row['requirement-' + r.DueAt] = Math.round(line.RoadFeeM2 || 0);
                    roadFeeM3J2Row['requirement-' + r.DueAt] = Math.round(line.RoadFeeM3J2 || 0);
                    roadFeeM3J3Row['requirement-' + r.DueAt] = Math.round(line.RoadFeeM3J3 || 0);
                }
            });

            if (direction === 'TO_HOME') {
                pinnedRows.bottom = [distanceTraveledRow, roadFeeM2Row, roadFeeM3J2Row, roadFeeM3J3Row];
            } else if (direction === 'FROM_HOME') {
                const returnFactoryRow: { [key: string]: any } = initializePinnedRow(-3, 'Visszaindulás a gyártól');

                requirements.forEach(r => {
                    const travelingPassengerWorkerIDs = r.Stops.flatMap(requirementStop => requirementStop.Passengers)
                        .filter(passenger => passenger.Travels)
                        .map(passenger => passenger.WorkerId);

                    const line = lines.find(l => l.RequirementID === r.ID);
                    if (line) {
                        returnFactoryRow['requirement-' + r.DueAt] = lines
                            .flatMap(line => line.Stops)
                            .flatMap(ls => ls.Passengers)
                            .filter(passenger => passenger.RequirementID === r.ID && travelingPassengerWorkerIDs.includes(passenger.WorkerID)).length;
                    }
                });

                pinnedRows.bottom = [returnFactoryRow, distanceTraveledRow, roadFeeM2Row, roadFeeM3J2Row, roadFeeM3J3Row];
            }

            return pinnedRows;
        };

        const createRowsFromStops = (stops: SortableLineStop[], requirements: Requirement[]): GridRowsProp => {
            let rows: GridRowsProp = [];

            const crmIDs = lines
                .flatMap(line => line.Stops)
                .filter(stop => stop.Type === 'stop')
                .map(stop => stop.CrmID);

            stops.forEach((stop, nthStop) => {
                const stopData: { [key: string]: any } = {
                    id: stop.ID,
                    crmId: stop.CrmID,
                    path: stop.Name,
                    type: 'STOP',
                    name: stop.Name,
                    stop: stop,
                    isHighPriority: getLinePriority(stop),
                    distance: lines.at(0)?.StopDistances?.at(nthStop)?.toFixed(1) || -1
                };

                if (stop.Type === 'stop') {
                    requirements.forEach(r => {
                        const requirementStop = r.Stops.find(s => s.CrmID === stop.CrmID && s.Name === stop.Name);

                        const passengers = lines
                            .flatMap(line => line.Stops)
                            .flatMap(ls => ls.Passengers)
                            .filter(p => p.StopCrmID === stop.CrmID && p.RequirementID === r.ID);

                        if (requirementStop) {
                            stopData['isAllPassengerHeadquarter'] = requirementStop.AllPassengerHeadquarter;
                            let travelPassengers = 0;
                            passengers.forEach(p => {
                                const passenger = rows.find(row => row.id === p.WorkerID);
                                const rpassenger = getRequirementPassenger(requirementStop, p.WorkerID);
                                if (passenger && rpassenger) {
                                    passenger['isRequirementPassenger' + r.DueAt] = true;
                                    passenger['rId' + r.DueAt] = rpassenger.ID;
                                    passenger['rTravel' + r.DueAt] = rpassenger.Travels;
                                    travelPassengers += rpassenger.Travels ? 1 : 0;
                                    return;
                                }
                                if (!passenger) {
                                    const passengerData: { [key: string]: any } = {
                                        id: p.WorkerID,
                                        path: stop.Name + '//' + p.Name + ' ' + p.WorkerID,
                                        type: 'PASSENGER',
                                        name: p.Name,
                                        passenger: p,
                                        stop: stop,
                                        isAllPassengerHeadquarter: requirementStop.AllPassengerHeadquarter,
                                    };
                                    requirements.forEach(r => (passengerData['requirement-' + r.DueAt] = 0));

                                    if (rpassenger) {
                                        passengerData['isRequirementPassenger' + r.DueAt] = true;
                                        passengerData['rId' + r.DueAt] = rpassenger.ID;
                                        passengerData['rTravel' + r.DueAt] = rpassenger.Travels;
                                        travelPassengers += rpassenger.Travels ? 1 : 0;
                                    }
                                    rows = rows.concat(passengerData);
                                }
                            });
                            stopData['requirement-' + r.DueAt] = travelPassengers;
                        }
                    });
                }

                if (stop.Type === 'site') {
                    stopData['type'] = 'SITE';
                    stopData['nSitePassengers'] = 0;

                    for (const r of requirements) {
                        stopData['requirement-' + r.DueAt] = 0;

                        for (const iterStop of r.Stops) {
                            if (!crmIDs.includes(iterStop.CrmID)) {
                                continue;
                            }

                            for (const passenger of iterStop.Passengers) {
                                if (
                                    passenger.Travels &&
                                    passenger.SiteName === stop.Name &&
                                    iterStop.RequirementID === r.ID &&
                                    lines
                                        .flatMap(line => line.Stops)
                                        .flatMap(ls => ls.Passengers)
                                        .some(p => p.WorkerID === passenger.WorkerId)
                                ) {
                                    stopData['requirement-' + r.DueAt] += 1;
                                    stopData['nSitePassengers'] += 1;
                                }
                            }
                        }
                    }
                }

                rows = rows.concat(stopData);
            });
            return rows;
        };

        const updateDataGridRows = (rowId: number, dueAt: string, stopId: number, passengerTravel: boolean) => {
            const updatePassengerData: { [key: string]: any } = {
                id: rowId,
            };
            updatePassengerData['rTravel' + dueAt] = passengerTravel;
            const updateStopData: { [key: string]: any } = {
                id: stopId,
            };
            const currentSum = apiRef.current.getRow(stopId)['requirement-' + dueAt];
            updateStopData['requirement-' + dueAt] = passengerTravel ? currentSum + 1 : currentSum - 1;
            apiRef.current.updateRows([updateStopData, updatePassengerData]);
            if (direction === 'FROM_HOME') {
                const returnFactoryRow = apiRef.current.getRow(-3);
                const currentSum = returnFactoryRow['requirement-' + dueAt];
                returnFactoryRow['requirement-' + dueAt] = passengerTravel ? currentSum + 1 : currentSum - 1;
                apiRef.current.updateRows([returnFactoryRow]);
            }
            apiRef.current.getAllRowIds().forEach(rowId => {
                const row = apiRef.current.getRow(rowId);
                if (row.type === 'SITE') {
                    const currentSum = row['requirement-' + dueAt];
                    row['requirement-' + dueAt] = passengerTravel ? currentSum + 1 : currentSum - 1;
                    apiRef.current.updateRows([row]);
                }
            });
        };

        useEffect(() => {
            const rows = createRowsFromStops(lineStops, requirements);
            setRows([...rows]);
            const pinRows = createGridPinRows();
            setGridPinRows(pinRows);
        }, [requirements, lineStops]);

        const getLinePriority = (currentStop: LineStop) => {
            const prevStop = lineStops.find(stop => stop.Position === currentStop.Position - 1);
            const nextStop = lineStops.find(stop => stop.Position === currentStop.Position + 1);

            const isBeforeMidnightTransition = currentStop.DepartureHour === 23 && nextStop && nextStop.DepartureHour === 0;
            const isAfterMidnightTransition = currentStop.DepartureHour === 0 && prevStop && prevStop.DepartureHour === 23;

            if (isBeforeMidnightTransition || isAfterMidnightTransition) {
                return '';
            }

            const isCurrentAfterPrev =
                prevStop &&
                (currentStop.DepartureHour > prevStop.DepartureHour ||
                    (currentStop.DepartureHour === prevStop.DepartureHour && currentStop.DepartureMinute > prevStop.DepartureMinute));

            const isCurrentBeforeNext =
                nextStop &&
                (currentStop.DepartureHour < nextStop.DepartureHour ||
                    (currentStop.DepartureHour === nextStop.DepartureHour && currentStop.DepartureMinute < nextStop.DepartureMinute));

            if (prevStop && !isCurrentAfterPrev) {
                return true;
            }

            if (nextStop && !isCurrentBeforeNext) {
                return true;
            }
            return false;
        };

        const getRequirementPassenger = (rStop: Stop, id: number) => {
            let passenger: Passenger | undefined;
            rStop.Passengers.forEach(p => {
                if (p.WorkerId === id) {
                    passenger = p;
                }
            });
            return passenger;
        };

        const requirementColumns: GridColDef[] = requirements.map(r => {
            return {
                field: 'requirement-' + r.DueAt,
                headerName: `${new Date(Date.parse(r.DueAt)).toLocaleDateString('hu-HU', { weekday: 'narrow' })}`,
                width: 35,
                minWidth: 35,
                maxWidth: 35,
                sortable: false,
                disableColumnMenu: true,
                headerAlign: 'center',
                align: 'center',
                headerClassName: 'requirement-header',
                resizable: false,
                display: 'flex',
                renderCell: (p: GridRenderCellParams) => {
                    if (p.row.type === 'PASSENGER') {
                        if (!p.row['isRequirementPassenger' + r.DueAt]) {
                            return <span style={{ margin: '0 2px' }}>-</span>;
                        }
                        return (
                            <PassengerCheckboxesCell
                                dueAt={r.DueAt}
                                rowId={p.row.id}
                                stopId={p.row.stop.id}
                                passengerId={p.row['rId' + r.DueAt]}
                                passengerTravel={p.row['rTravel' + r.DueAt]}
                                updateDataGridRows={updateDataGridRows}
                            />
                        );
                    }
                },
            };
        });

        const stopDeletabilityMap = useMemo(() => {
            const deletabilityMap: StopDeletabilityMap = {};
            for (const requirement of requirements) {
                for (const stop of requirement.Stops) {
                    if (deletabilityMap.hasOwnProperty(stop.Name)) {
                        if (deletabilityMap[stop.Name] && !!stop.NPassengers) {
                            deletabilityMap[stop.Name] = false;
                        }
                        continue;
                    }
                    deletabilityMap[stop.Name] = !stop.NPassengers;
                }
            }
            return deletabilityMap;
        }, [requirements]);

        return (
            <DndContext
                onDragStart={e => setActiveStop(e.active.data?.current?.stop)}
                onDragEnd={e => {
                    const { active, over } = e;
                    if (over && active.id !== over?.id) {
                        const originalIdx = lineStops.map(s => s.id).indexOf(active.id);
                        const newIdx = lineStops.map(s => s.id).indexOf(over.id);

                        const reorderedArray =
                            originalIdx > newIdx
                                ? [
                                      ...lineStops.slice(0, newIdx),
                                      lineStops[originalIdx],
                                      ...lineStops.slice(newIdx, originalIdx),
                                      ...lineStops.slice(originalIdx + 1),
                                  ]
                                : [
                                      ...lineStops.slice(0, originalIdx),
                                      ...lineStops.slice(originalIdx + 1, newIdx + 1),
                                      lineStops[originalIdx],
                                      ...lineStops.slice(newIdx + 1),
                                  ];

                        const stopNamePositionMap: Record<string, number> = {};

                        reorderedArray.forEach((s, index) => (stopNamePositionMap[s.Name] = index));

                        const allLineStops = lines.flatMap(l =>
                            l.Stops.map(s => {
                                s.Position = stopNamePositionMap[s.Name];
                                return s;
                            })
                        );

                        saveStopChange(allLineStops);
                        setLineStops(reorderedArray);
                        return;
                    }
                    setActiveStop(null);
                }}>
                <Stack>
                    <ErrorBoundary>
                        <DataGridPro
                            treeData
                            rowPositionsDebounceMs={8}
                            apiRef={apiRef}
                            disableRowSelectionOnClick
                            disableMultipleRowSelection
                            disableChildrenFiltering
                            disableColumnResize
                            getTreeDataPath={row => row.path.split('//')}
                            groupingColDef={groupingColDef}
                            autoHeight={true}
                            pinnedRows={gridPinRows}
                            hideFooter={true}
                            rows={rows.map(row => {
                                const extendedRowDetails: GridValidRowModel = {
                                    ...row,
                                };
                                extendedRowDetails.exchangeLineStopHandler = handleLineStopExchange;
                                extendedRowDetails.otherLines = allLines.flatMap(l => l.Stops).filter((lineStop: LineStop) => row.name === lineStop.Name);
                                extendedRowDetails.lineNames = lineNames.filter(lineName => lineName !== lines[0].Name);
                                extendedRowDetails.lines = lines;
                                extendedRowDetails.setLineStops = setLineStops;
                                extendedRowDetails.sortStops = sortStops;
                                extendedRowDetails.deleteLineStopHandler = handleDeleteLineStop;
                                extendedRowDetails.deleteLineStopPassengerHandler = handleDeleteLineStopPassenger;
                                extendedRowDetails.deleteIconColor = stopDeletabilityMap[row.name] ? 'error' : 'action';
                                return extendedRowDetails;
                            })}
                            columns={[...columns.slice(0, columns.length - 1), ...requirementColumns, columns[columns.length - 1]]}
                            rowHeight={30}
                            columnHeaderHeight={30}
                            isGroupExpandedByDefault={params => {
                                return params.groupingKey ? expandedRows.hasOwnProperty(params.groupingKey.toString()) : false;
                            }}
                            sx={{
                                '& .requirement-item-cell-overcapacity': {
                                    color: '#f44336',
                                },
                                '& .MuiDataGrid-columnHeader': {
                                    padding: 0,
                                },
                                '& .MuiDataGrid-cell': {
                                    padding: 0,
                                },
                                '& .requirement-item-cell': {
                                    padding: 0,
                                },
                            }}
                        />
                    </ErrorBoundary>
                    <DragOverlay dropAnimation={null}>{activeStop ? <Typography sx={{ width: 400 }}>{activeStop.Name}</Typography> : null}</DragOverlay>
                </Stack>
            </DndContext>
        );
    }
);

export { LineStopList };
