import {Button, Tooltip} from "@mui/material";
import React from "react";

type RoundedFormButtonProps = {
    disabled?: boolean
    title: string
    icon: JSX.Element
    onClick: () => void
    color?: "inherit" | "error" | "primary" | "secondary" | "info" | "success" | "warning" | undefined
}

const RoundedFormButton = ({title, icon, onClick, disabled, color}: RoundedFormButtonProps) => {
    return <Tooltip title={title} arrow>
        <Button
            variant='contained'
            disabled={disabled}
            size={'large'}
            color={color}
            sx={{
                cursor: 'pointer',
                borderRadius: '50px',
                minWidth: 0,
                padding: 1,
                textAlign: 'center',
                mr: 0.5,
                '.MuiButton-startIcon': {margin: 0},
            }}
            onClick={onClick}
            startIcon={icon}
        />
    </Tooltip>
}
export {RoundedFormButton}