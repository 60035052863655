import React, {useEffect} from "react";
import {Box} from "@mui/material";
import {Layout} from "./Layout";
import {useAuth} from "../../utils";

type Props = {
    children: JSX.Element | JSX.Element[]
}

const SecuredLayout: React.FC<Props> = ({children}) => {
    const {user} = useAuth();

    useEffect(() => {
        if (!user?.accessToken) {
            return  ;
        }
    }, []);

    return <Layout>
            <Box>
                {children}
            </Box>
        </Layout>
}

export {SecuredLayout}