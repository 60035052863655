import React, {createContext, useContext, useState} from 'react';
import {StopCheckboxContextType, StopIdIsOnMapType} from '../@types/map';

const StopCheckboxContext = createContext<StopCheckboxContextType>({
    stopSelectionStatus: {},
    setStopSelectionStatus: (idMap: StopIdIsOnMapType) => {},
});

export const useRequirementStopSelectionContext = () => useContext(StopCheckboxContext);

export const StopCheckboxProvider = ({ children }: { children: React.ReactNode }) => {
    const [stopSelectionStatus, setStopSelectionStatus] = useState<StopIdIsOnMapType>({});

    return <StopCheckboxContext.Provider value={{
        stopSelectionStatus,
        setStopSelectionStatus
    }}>{children}</StopCheckboxContext.Provider>;
};
