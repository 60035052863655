import React, { useCallback, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Breadcrumbs, Button, FormControl, Grid, InputLabel, Link as MuiLink, MenuItem, Select } from '@mui/material';
import { SecuredLayout } from '../Layout/SecuredLayout';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Export } from '../../@types/schedule';
import ScheduleLineGroup from './ScheduleLineGroup';
import { getEnumKeyByValue, useAuth, useProvideSnackBar } from '../../utils';
import { Role } from '../../@types/auth';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import { WorkplaceQueryNameNameMap } from '../../@types/workplace';

type WorkplacePlans = {
    [key: string]: { [key: string]: Array<number> };
};

type PlanExportResponse = {
    Export: Export;
};

export enum GroupByType {
    LINE_NAME = 'járatnév',
    SHIFT = 'műszak',
}

export enum DirectionType {
    FROM_HOME = 'munkahelyre',
    TO_HOME  = 'haza',
}

export default function Schedule() {
    const { user } = useAuth();
    const { showError } = useProvideSnackBar();
    const [searchParams] = useSearchParams();

    const [workplacePlanList, setWorkplacePlanList] = useState<WorkplacePlans>({});

    const [workplace, setWorkplace] = useState<string>(
        user !== undefined && user.workplace ? WorkplaceQueryNameNameMap[user.workplace as keyof typeof WorkplaceQueryNameNameMap] : 'please_select'
    );

    const [plan, setPlan] = useState<string>(searchParams.get('planID') || 'please_select');
    const [exportData, setExportData] = useState<Export>();
    const [groupBy, setGroupBy] = useState<keyof typeof GroupByType>('LINE_NAME');
    const [direction, setDirection] = useState<keyof typeof DirectionType>('FROM_HOME');

    const xlsxExportHref = () => {
        if (!(workplacePlanList[workplace] && workplacePlanList[workplace][plan])) return '';
        const params = {
            Workplace: workplace,
            GroupBy: groupBy,
            Direction: direction,
            PlanIDs: workplacePlanList[workplace][plan].join(','),
            t: user?.accessToken || '',
        };
        return '/api/plans/xlsx_export?' + new URLSearchParams(params).toString();
    };

    const fetchPlans = useCallback(async () => {
        const resp = await fetch('/api/plans/export', {
            headers: {
                Authorization: `Bearer ${user?.accessToken}`,
            },
        });

        if (resp.status === 200) {
            const planList = await resp.json();

            const queryPlanName = searchParams.get('plan');
            if (queryPlanName) {
                setPlan(queryPlanName);
            }

            setWorkplacePlanList(planList);
            return;
        }

        if (resp.status === 401) {
            showError('A munkamenet lejárt, kérjük jelentkezzen be újra!');
            return;
        }

        showError('Hiba a tervek letöltése közben!');
    }, [user]);

    const fetchExport = async () => {
        if (!(workplacePlanList && workplacePlanList[workplace] && workplacePlanList[workplace][plan])) {
            return;
        }
        const resp = await fetch('/api/plans/export', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${user?.accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                PlanIDs: workplacePlanList[workplace][plan],
                GroupBy: groupBy,
                Direction: direction,
            }),
        });

        if (!resp.ok) {
            showError('Hiba a menetrend letöltése közben!');
            return;
        }

        if (resp.status === 200) {
            const respBody: PlanExportResponse = await resp.json();
            setExportData(respBody.Export);
        }
    };

    useEffect(() => {
        fetchPlans();
    }, []);

    useEffect(() => {
        if (plan !== 'please_select') {
            fetchExport();
        }
    }, [plan, groupBy, direction]);

    return (
        <SecuredLayout>
            <Grid container p={2} spacing={2} sx={{ alignItems: 'end' }}>
                <Grid container px={1} py={1} spacing={1}>
                    <Grid item xs={12} px={1.5} py={0.5} textAlign={'right'}>
                        {user?.roles.includes(Role.DOLGOZO_HR) || user?.roles.includes(Role.DOLGOZO_MUSZAKVEZ) ? null : (
                            <Breadcrumbs aria-label='breadcrumb' sx={{ display: 'inline-block' }}>
                                <MuiLink
                                    component={Link}
                                    underline='hover'
                                    sx={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}
                                    color='inherit'
                                    to='/planner'>
                                    Tervező
                                    <EditRoadIcon sx={{ ml: 0.5, mb: -0.25 }} fontSize={'small'} />
                                </MuiLink>
                            </Breadcrumbs>
                        )}
                    </Grid>
                </Grid>

                {user?.roles.includes(Role.DOLGOZO_HR) || user?.roles.includes(Role.DOLGOZO_MUSZAKVEZ) ? null : (
                    <Grid item xs={12} sm={2} md={'auto'}>
                        <FormControl fullWidth>
                            <InputLabel id='workplace-label' variant='standard'>
                                Partner
                            </InputLabel>
                            <Select
                                variant='standard'
                                labelId='workplace-label'
                                value={workplace}
                                label='Partner'
                                sx={{ minWidth: '200px' }}
                                onChange={e => {
                                    setWorkplace(e.target.value);
                                }}>
                                <MenuItem value={'please_select'}>Kérem válasszon!</MenuItem>
                                {Object.keys(workplacePlanList).map(workplace => {
                                    return (
                                        <MenuItem key={workplace} value={workplace}>
                                            {workplace}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                <Grid item xs={12} sm={'auto'}>
                    <FormControl fullWidth>
                        <InputLabel id='plan-label' variant='standard'>
                            Menetrend
                        </InputLabel>
                        <Select
                            variant='standard'
                            labelId='plan-label'
                            value={plan}
                            label='menetrend'
                            sx={{ minWidth: '400px' }}
                            onChange={e => setPlan(e.target.value)}>
                            <MenuItem value={'please_select'}>Kérem válasszon!</MenuItem>

                            {Object.keys(workplacePlanList[workplace] ?? {}).map((planName: string) => {
                                return (
                                    <MenuItem key={planName} value={planName}>
                                        {planName}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={'auto'}>
                    <FormControl fullWidth>
                        <InputLabel id='groupby-label' variant='standard'>
                            Csoportosítás
                        </InputLabel>
                        <Select
                            variant='standard'
                            labelId='groupby-label'
                            value={groupBy}
                            label='Csoportosítás'
                            sx={{ minWidth: '100px' }}
                            onChange={e => setGroupBy(e.target.value as keyof typeof GroupByType)}>
                            <MenuItem value={getEnumKeyByValue(GroupByType, GroupByType.LINE_NAME)}>{GroupByType.LINE_NAME}</MenuItem>
                            <MenuItem value={getEnumKeyByValue(GroupByType, GroupByType.SHIFT)}>{GroupByType.SHIFT}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={'auto'}>
                    <FormControl fullWidth>
                        <InputLabel id='groupby-label' variant='standard'>
                            Irány
                        </InputLabel>
                        <Select
                            variant='standard'
                            labelId='groupby-label'
                            value={direction}
                            label='Irány'
                            sx={{ minWidth: '100px' }}
                            onChange={e => setDirection(e.target.value as keyof typeof DirectionType)}>
                            <MenuItem value={getEnumKeyByValue(DirectionType, DirectionType.FROM_HOME)}>{DirectionType.FROM_HOME}</MenuItem>
                            <MenuItem value={getEnumKeyByValue(DirectionType, DirectionType.TO_HOME)}>{DirectionType.TO_HOME}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <Button endIcon={<FileDownloadIcon />} variant='contained' href={xlsxExportHref()} target='_blank' sx={{ borderRadius: '50px' }}>
                        Menetrend letöltése
                    </Button>
                </Grid>
            </Grid>

            <Grid container p={2} spacing={2}>
                {exportData &&
                    Object.entries(exportData).map(([lineName, lineGroup]) => {
                        return (
                            <Grid item key={lineName} xs={6} p={2}>
                                <ScheduleLineGroup workplace={workplace} lineGroup={lineGroup} displayLineName={groupBy === 'SHIFT'} direction={direction} />
                            </Grid>
                        );
                    })}
            </Grid>
        </SecuredLayout>
    );
}
