import { DragIndicator } from '@mui/icons-material';
import { SortableItem } from './SortableItem';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { SortableLineStop } from '../../@types/lines';

interface StopDndContextProps {
    stop: SortableLineStop;
}

export default function StopDndContext({ stop }: StopDndContextProps) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: stop.ID,
        data: {
            stop: stop,
        },
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
    return (
        <SortableItem ref={setNodeRef} {...attributes} style={style}>
            <DragIndicator
                {...listeners}
                sx={{
                    verticalAlign: 'middle',
                    cursor: 'grab',
                    transition,
                    transform,
                }}
            />
        </SortableItem>
    );
}
