export enum DirectionType {
    FROM_HOME = 'munkahely',
    TO_HOME = 'haza',
}

export type Stop = {
    ID: number;
    Latitude: number;
    Longitude: number;
    NPassengers: number;
    Name: string;
    RequirementID: number;
    CrmID: number;
    AllPassengerHeadquarter: boolean;
    CreatedAt: string;
    Passengers: Passenger[];
};

export type Passenger = {
    ID: number;
    Name: string;
    Travels: boolean;
    WorkerId: number;
    WorkerIdNumber: string;
    SiteAddress: string;
    SiteName: string;
};

export type StopMaxNPassengers = {
    [key: number]: number;
};

export type Shift = {
    Name: string;
    StartAt: string;
    ArrivalAt: string;
    DepartureAt: string;
    CountOfWorkers: number;
};

export type OperationUnit = {
    Name: string;
};

export type DirectionShiftListMap = {
    [key in 'FROM_HOME' | 'TO_HOME']: Shift[];
};

export type Workplace = {
    Address: string;
    Email: string;
    Name: string;
    PhoneNumber: string;
    QueryName: string;
    Shifts: DirectionShiftListMap;
    Longitude: number;
    Latitude: number;
    TravelTime: number;
    OperationUnits: string[];
};

export type Plan = {
    PlanID?: number;
    PlanName: string;
    StartAt?: string;
    EndAt?: string;
    MobileAppSyncedAt?: string | null;
    EmailNotificationsSentAt?: string | null;
    LastChangedAt: string;
    TravelTime?: number;
    ReverseDirectionPlanID?: number;
};

export type Requirement = {
    ID: number;
    CreatedAt: string;
    Direction: string;
    DueAt: string;
    Shifts: Shift[];
    OperationUnits: OperationUnit[];
    Stops: Stop[];
    UpdatedAt: string;
    Workplace: string;
    ShiftArrivalAt: string;
    ShiftDepartureAt: string;
    PlanID: number;
};

export type RequirementFilterParams = {
    workplace: Workplace;
    shifts: string[];
    direction: keyof typeof DirectionType;
    from: Date;
    to: Date;
    planID: string | null | undefined;
};

export type PlanMap = {
    [key: string]: Plan[];
};

export type Plans = {
    Plans: PlanResponse[];
};

export type PlanResponse = {
    ID: number;
    CreatedAt: string;
    Direction: string;
    StartAt: string;
    EndAt: string;
    Name: string;
    NameSuffix: string;
    Requirements: Requirement[];
    ScheduleName: string;
    Workplace: string;
    MobileAppSyncedAt?: string | null;
    EmailNotificationsSentAt?: string | null;
    LastChangedAt: string;
    PassengerListChangedAt?: string | null;
    TravelTime: number;
    ReverseDirectionPlanID?: number;
    ShiftName: string;
    OperationUnitName: string;
    UpdatedBy: string;
    CreatedBy: string;
};