import { Box, Button } from '@mui/material';
import { BusTypes } from '../../@types/lines';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useAuth, useProvideSnackBar } from '../../hooks';

interface NewLineProps {
    requirements: any;
    afterCreate: () => void;
}

export default function NewLine({ requirements, afterCreate }: NewLineProps) {
    const { user } = useAuth();
    const { showResponseError } = useProvideSnackBar();

    const createNewLine = async () => {
        const response = await fetch('/api/lines', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.accessToken}`,
            },
            body: JSON.stringify({
                Lines: requirements.map((item: any) => {
                    return {
                        RequirementID: item.ID,
                        Capacity: BusTypes[3].capacity,
                        CrmID: item.CrmID
                    };
                }),
            }),
        });

        if (!response.ok) {
            await showResponseError(response);
            return;
        }
        afterCreate();
    };

    return (
        <Box display={'flex'} justifyContent={'center'}>
            <Button
                variant={'contained'}
                color='primary'
                size={'small'}
                onClick={() => createNewLine()}
                sx={{ borderRadius: '50px', width: '100%', textAlign: 'center' }}>
                <AddCircleIcon sx={{ padding: 0.25 }} />
                Új járat
            </Button>
        </Box>
    );
}
