import { Button, CardActions, CardContent, Grid, Modal, Typography } from '@mui/material';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Line } from '../../@types/lines';
import { ConfirmCard } from './Styles';

interface DeleteLineModalProps {
    line?: Line;
    open: boolean;
    onClose: () => void;
    onDelete: () => void;
}

export default function DeleteLineModal({ line, open, onClose, onDelete }: DeleteLineModalProps) {
    if (line === undefined) {
        return null;
    }

    return (
        <Grid>
            <Modal open={open} onClose={onClose}>
                <ConfirmCard>
                    <CardContent sx={{ p: 2 }}>
                        <Typography variant={'h5'} pb={3}>
                            Járat törlés
                        </Typography>
                        <Typography paragraph>
                            Tényleg törölni szeretné a <strong>{line.Name}</strong> járatot?
                            <br /> A törlés nem visszavonható!
                        </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'space-between' }}>
                        <Button
                            sx={{ mx: 1, borderRadius: 50 }}
                            variant={'contained'}
                            size={'small'}
                            color={'info'}
                            onClick={() => onClose()}
                            startIcon={<ArrowBackIosNewTwoToneIcon />}>
                            Mégsem
                        </Button>
                        <Button
                            sx={{ mx: 1, borderRadius: 50 }}
                            variant={'contained'}
                            size={'small'}
                            color={'error'}
                            onClick={onDelete}
                            endIcon={<DeleteForeverIcon />}>
                            Törlés
                        </Button>
                    </CardActions>
                </ConfirmCard>
            </Modal>
        </Grid>
    );
}
