import { Button, CardActions, CardContent, CircularProgress, Modal, Typography } from '@mui/material';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import { useAuth, useProvideSnackBar } from '../../utils';
import EmailIcon from '@mui/icons-material/Email';
import { useState } from 'react';
import { ConfirmCard } from './Styles';

interface SendEmailModalProps {
    open: boolean;
    onClose: () => void;
    planID: number | undefined;
    setEmailNotificationsSentAt: (emailNotificationsSentAt: Date) => void;
}

export default function SendEmailModal({ open, onClose, planID, setEmailNotificationsSentAt }: SendEmailModalProps) {
    const { user } = useAuth();
    const { showError, showSuccess, showResponseError } = useProvideSnackBar();
    const [loading, setLoading] = useState(false);

    const handlePublish = async () => {
        if (loading) return;
        if (!planID) {
            showError(`Hiba: Hiányzó menetrend`);
            return;
        }

        setLoading(true);

        try {
            const response = await fetch('/api/plans/publish', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    PlanID: planID,
                    RunMobileAppSync: false,
                    RunEmailDelivery: true,
                }),
            });
            if (response.ok) {
                showSuccess(`Értesítő e-mail küldése sikeres volt`);
                setEmailNotificationsSentAt(new Date());
                return;
            }
            showResponseError(response);
        } catch (error: any) {
            showError('Hiba történt az értesítő e-mail küldése közben');
        } finally {
            setLoading(false);
            onClose();
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <ConfirmCard>
                <CardContent sx={{ p: 2 }}>
                    <Typography variant={'h6'} pb={3}>
                        Értesítő e-mail küldése
                    </Typography>
                    <Typography paragraph>
                        Az <strong>E-mail küldés</strong> gombra kattintva a menetrenderől e-mail-t küldünk a partner HR dolgozóinaik.
                    </Typography>
                    <Typography paragraph>Tényleg szeretne e-mail értesítést küldeni?</Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'space-between' }}>
                    <Button
                        sx={{ mx: 1, borderRadius: 50 }}
                        variant={'contained'}
                        size={'small'}
                        color={'info'}
                        onClick={() => onClose()}
                        startIcon={<ArrowBackIosNewTwoToneIcon />}>
                        mégsem
                    </Button>
                    <Button
                        variant='contained'
                        size={'small'}
                        sx={{ borderRadius: 50, marginRight: 0.25 }}
                        onClick={() => handlePublish()}
                        endIcon={loading ? <CircularProgress size={20} color='inherit' /> : <EmailIcon />}>
                        E-mail küldés
                    </Button>
                </CardActions>
            </ConfirmCard>
        </Modal>
    );
}
