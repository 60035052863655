import { Checkbox, Grid } from '@mui/material';
import { useAuth, useProvideSnackBar } from '../../hooks';

interface PassengerCheckboxesCellProps {
    rowId: number;
    dueAt: string;
    stopId: number;
    passengerId: number;
    passengerTravel: boolean;
    updateDataGridRows: (rowId: number, dueAt: string, stopId: number, passengerTravel: boolean) => void;
}

const PassengerCheckboxesCell: React.FC<PassengerCheckboxesCellProps> = ({ rowId, dueAt, stopId, passengerId, passengerTravel, updateDataGridRows }) => {
    const { showError, showResponseError } = useProvideSnackBar();
    const { user } = useAuth();

    const fetchPassengerTravel = async () => {
        try {
            const response = await fetch('/api/travels', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    RequirementStopPassengerID: passengerId,
                    Travel: !passengerTravel,
                }),
            });

            if (response.ok) {
                updateDataGridRows(rowId, dueAt, stopId, !passengerTravel);
            } else {
                showResponseError(response);
            }
        } catch (error: any) {
            showError('Hiba történt: ' + error.message);
        }
    };

    return (
        <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Checkbox
                size='small'
                key={passengerId}
                checked={passengerTravel}
                onChange={event => {
                    fetchPassengerTravel();
                }}
                sx={{ padding: 0, paddingTop: 0 }}
            />
        </Grid>
    );
};

export default PassengerCheckboxesCell;
