import React from 'react';
import { Card, CardContent, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { LineGroup, Passenger } from '../../@types/schedule';
import { formatDateToYyyyMMdd } from '../../utils';

type Props = {
    workplace: string;
    lineGroup: LineGroup;
    displayLineName: boolean;
    direction: string;
};

export default function ScheduleLineGroup({ lineGroup, displayLineName, direction, workplace }: Props) {
    return (
        <Grid item>
            <Card
                variant='elevation'
                sx={{
                    border: '1px solid #eaeaea',
                    padding: '1px',
                }}>
                <Typography variant='h6' style={{ textAlign: 'center', paddingTop: '2px' }}>
                    {lineGroup.Name}
                </Typography>
                <CardContent>
                    {lineGroup.LineInstances.map(lineInstance => {
                        return (
                            <TableContainer key={JSON.stringify(lineInstance.Stops)} sx={{ marginTop: '4px' }}>
                                <Table size='small'>
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: '#eaeaea' }}>
                                            <TableCell colSpan={2}>{displayLineName ? lineInstance.Name : null}</TableCell>
                                            <TableCell sx={{ p: 0 }} />
                                            {lineGroup.Days.map(day => {
                                                return (
                                                    <TableCell align={'center'} key={`head_${day}`}>
                                                        {new Date(day).toLocaleDateString('hu-HU', { weekday: 'short' })}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {lineInstance.Stops.map(stop => (
                                            <React.Fragment key={stop.Name}>
                                                <TableRow>
                                                    <TableCell>
                                                        {String(stop.DepartureHour).padStart(2, '0')}:{String(stop.DepartureMinute).padStart(2, '0')}
                                                    </TableCell>
                                                    <TableCell sx={{ width: '170px' }} colSpan={stop.Type === 'site' ? 2 : 1}>
                                                        {stop.Name}
                                                    </TableCell>
                                                    {stop.Type === 'site' &&
                                                        lineGroup.Days?.map(day => {
                                                            const currentDay = formatDateToYyyyMMdd(new Date(day));
                                                            return (
                                                                <TableCell key={`site_${stop.Name}_${currentDay}`} align={'center'}>
                                                                    {stop.SitePassengers.filter(item => item.Days[currentDay] !== undefined).length}
                                                                </TableCell>
                                                            );
                                                        })}

                                                    {stop.Type === 'stop' && (
                                                        <PassengerData
                                                            key={`passenger_${stop.Name}_${stop.Passengers[0]}`}
                                                            lineGroup={lineGroup}
                                                            passenger={stop.Passengers[0]}
                                                        />
                                                    )}
                                                </TableRow>
                                                {stop.Type === 'stop' &&
                                                    stop.Passengers.slice(1).map(passenger => {
                                                        return (
                                                            <TableRow key={passenger.Name}>
                                                                <TableCell colSpan={2} />
                                                                <PassengerData
                                                                    key={`passenger_${stop.Name}_${passenger}`}
                                                                    lineGroup={lineGroup}
                                                                    passenger={passenger}
                                                                />
                                                            </TableRow>
                                                        );
                                                    })}
                                            </React.Fragment>
                                        ))}
                                        {/* <TableRow>
                                            {direction === 'FROM_HOME' && lineInstance.ShiftArriveAt !== '00:00' ? (
                                                <>
                                                    <TableCell>{lineInstance.ShiftArriveAt}</TableCell>
                                                    <TableCell colSpan={2}>érkezés a gyárhoz</TableCell>
                                                </>
                                            ) : (
                                                <>
                                                    <TableCell colSpan={3}></TableCell>
                                                </>
                                            )}
                                            {lineGroup.Days.map(day => {
                                                return (
                                                    <TableCell align={'center'} key={`sum_${day}`}>
                                                        {lineInstance.DailyPassengerSum[formatDateToYyyyMMdd(new Date(day))]}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow> */}
                                        {direction === 'FROM_HOME' && lineInstance.ShiftDepartureAt !== '00:00' && (
                                            <>
                                                <TableRow>
                                                    <TableCell>{lineInstance.ShiftDepartureAt}</TableCell>
                                                    <TableCell colSpan={lineGroup.Days.length + 2}>visszaindulás a gyártól</TableCell>
                                                </TableRow>
                                            </>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        );
                    })}
                </CardContent>
            </Card>
        </Grid>
    );
}

function PassengerData({ passenger, lineGroup }: { passenger: Passenger; lineGroup: LineGroup }) {
    return (
        <>
            <TableCell sx={{ width: '170px' }}>{passenger?.Name}</TableCell>
            {lineGroup.Days?.map(day => {
                const currentDay = passenger?.Days[formatDateToYyyyMMdd(new Date(day))];
                return (
                    <TableCell key={`passenger_${day}`} align={'center'}>
                        {currentDay && currentDay.Going ? '1' : '0'}
                    </TableCell>
                );
            })}
        </>
    );
}
