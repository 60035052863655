import styled from '@emotion/styled';
import { Box, CardContent } from '@mui/material';
import { DailyPassengers, Line, LineStop, SortableLineStop } from '../../@types/lines';
import React, { useEffect, useState } from 'react';
import { DirectionType, Requirement } from '../../@types/requirements';
import { LineProperties } from './LineProperties';
import { useDroppable } from '@dnd-kit/core';
import { LineStopList } from './LineStopList';
import { LineIsOnMapState } from '../../@types/map';
import { Workplace } from '../../@types/workplace';
import LineColor from './LineColor';

const Card = styled(Box)`
    margin: 0 0 20px 0;
    border-radius: 5px;
`;

type LineDetailsProps = {
    requirements: Requirement[];
    lines: Line[];
    onDelete: (lines: Line[]) => void;
    afterChangeCallback: () => void;
    linesForMap: LineIsOnMapState;
    setLinesForMap: React.Dispatch<React.SetStateAction<LineIsOnMapState>>;
    workplace: Workplace;
    allLines: Line[];
    fetchRequirements: () => void;
    onSynced: boolean;
    direction: keyof typeof DirectionType;
};

const LineInformation = ({
    requirements,
    lines,
    onDelete,
    afterChangeCallback,
    linesForMap,
    setLinesForMap,
    workplace,
    allLines,
    fetchRequirements,
    onSynced,
    direction,
}: LineDetailsProps) => {
    const [stops, setStops] = useState<SortableLineStop[]>([]);
    const [dailySumPassengers, setDailySumPassengers] = useState<DailyPassengers>({});

    const { isOver, setNodeRef } = useDroppable({
        id: lines[0].Name,
        data: {
            lineName: lines[0].Name,
        },
    });

    const sortStops = (lines: Line[]) => {
        const s = lines
            .flatMap(l => l.Stops)
            .reduce((uniques: SortableLineStop[], stop: LineStop) => {
                if (uniques.find(s => s.Name === stop.Name) === undefined) {
                    const sortable = { ...stop, id: stop.ID };
                    uniques.push(sortable);
                }
                return uniques;
            }, []);
        s.sort((a, b) => {
            return a.Position - b.Position;
        });
        return s;
    };

    useEffect(() => {
        setStops(sortStops(lines));
    }, [lines]);

    useEffect(() => {
        const requirementSumPassengers: DailyPassengers = {};
        const lineStopPassengers = lines.flatMap(line => line.Stops).flatMap(lineStop => lineStop.Passengers);

        requirements.forEach(r => {
            const travelingPassengerWorkerIDs = r.Stops.flatMap(requirementStop => requirementStop.Passengers)
                .filter(passenger => passenger.Travels)
                .map(passenger => passenger.WorkerId);

            requirementSumPassengers[r.ID] = lineStopPassengers.filter(
                lineStopPassenger => lineStopPassenger.RequirementID === r.ID && travelingPassengerWorkerIDs.includes(lineStopPassenger.WorkerID)
            ).length;
        });
        setDailySumPassengers(requirementSumPassengers);
    }, [requirements, lines]);

    return (
        <Card
            ref={setNodeRef}
            sx={{
                border: `3px solid ${LineColor(allLines, lines[0].ID)}`,
                backgroundColor: isOver ? '#cffcf3' : undefined,
            }}>
            <CardContent key={lines[0].Name} sx={{ p: 0 }}>
                <LineProperties
                    requirements={requirements}
                    line={lines[0]}
                    ids={lines.map((item: Line) => item.ID)}
                    onDeleteLineClick={() => onDelete(lines)}
                    dailyPassengers={dailySumPassengers}
                    afterChangeCallback={afterChangeCallback}
                    linesForMap={linesForMap}
                    setLinesForMap={setLinesForMap}
                    lineStops={stops}
                    workplace={workplace}
                    onSynced={onSynced}
                    direction={direction}
                />
                <LineStopList
                    requirements={requirements}
                    lines={lines}
                    lineStops={stops}
                    setLineStops={(ls: SortableLineStop[]) => setStops(ls)}
                    afterChangeCallback={afterChangeCallback}
                    dailyPassengers={dailySumPassengers}
                    lineNames={Object.keys(linesForMap)}
                    allLines={allLines}
                    fetchRequirements={fetchRequirements}
                    direction={direction}
                    sortStops={sortStops}
                />
            </CardContent>
        </Card>
    );
};

export { LineInformation };
