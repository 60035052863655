import { Button, CardActions, CardContent, CircularProgress, Modal, Typography } from '@mui/material';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import { useAuth, useProvideSnackBar } from '../../utils';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import { ConfirmCard } from './Styles';

interface DeletePlansModalProps {
    open: boolean;
    onClose: () => void;
    planID: number | undefined;
    onDeletePlan: () => void;
}

export default function DeletePlansModal({ open, onClose, planID, onDeletePlan  }: DeletePlansModalProps) {
    const { user } = useAuth();
    const { showError, showSuccess } = useProvideSnackBar();
    const [loading, setLoading] = useState(false);

    const fetchDeletePlans = async (planID: number | undefined) => {
        if (loading) return;
        if (!planID) {
            showError(`Hiba: Hiányzó menetrend`);
            return;
        }
        setLoading(true);
        try {
            const response = await fetch(`/api/plans`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({ ID: planID }),
            });

            if (response.ok) {
                showSuccess(`Menetrend törlése sikeres volt`);
                onDeletePlan();
            } else if (response.status === 400) {
                throw new Error(`Ez a menetrend már fel lett töltve a Buszom app-ba`);
            } else if (response.status === 404) {
                throw new Error(`Ez a menetrend már törölve lett`);
            } else {
                throw new Error('Failed to delete data');
            }
        } catch (error: any) {
            showError(`Hiba történt a menetrend törlése közben: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <ConfirmCard>
                <CardContent sx={{ p: 2 }}>
                    <Typography variant={'h6'} pb={3}>
                        Menetrend törlés megerősítése
                    </Typography>
                    <Typography paragraph>
                        A <strong>törlés</strong> gombra kattintva a menetrendet törölni fogjuk, mely nem visszavonható.
                    </Typography>
                    <Typography paragraph>Tényleg szeretné törölni a menetrendet?</Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'space-between' }}>
                    <Button
                        sx={{ mx: 1, borderRadius: 50 }}
                        variant={'contained'}
                        size={'small'}
                        color={'info'}
                        onClick={() => onClose()}
                        startIcon={<ArrowBackIosNewTwoToneIcon />}>
                        mégsem
                    </Button>
                    <Button
                        variant='contained'
                        color='error'
                        size={'small'}
                        sx={{ borderRadius: 50, marginRight: 0.25 }}
                        onClick={() => fetchDeletePlans(planID)}
                        endIcon={loading ? <CircularProgress size={20} color='inherit' /> : <DeleteIcon />}>
                        törlés
                    </Button>
                </CardActions>
            </ConfirmCard>
        </Modal>
    );
}
