import { Button, CardActions, CardContent, CircularProgress, Modal, Typography } from '@mui/material';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import { useAuth, useProvideSnackBar } from '../../utils';
import { useState } from 'react';
import { ConfirmCard } from './Styles';
import { Plan } from '../../@types/requirements';

interface AutoTimeTableModalProps {
    onUpdate: () => void;
    open: boolean;
    onClose: () => void;
    plan: Plan;
}

export default function AutoTimeTableModal({ onUpdate, open, onClose, plan }: AutoTimeTableModalProps) {
    const { user } = useAuth();
    const { showError, showSuccess, showResponseError } = useProvideSnackBar();
    const [loading, setLoading] = useState(false);

    const handleAutoTimeTable = async () => {
        if (!plan.PlanID) {
            showError('Hiányzó terv azonosító');
            return;
        }
        setLoading(true);
        try {
            const response = await fetch('/api/lines/plan_auto_timetable', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    PlanID: plan.PlanID,
                }),
            });
            if (response.ok) {
                showSuccess(`Sikeres időpontozás`);
                onUpdate();
                return;
            }
            showResponseError(response);
        } catch (error: any) {
            showError('Hiba történt az időpontozás közben');
        } finally {
            setLoading(false);
            onClose();
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <ConfirmCard>
                <CardContent sx={{ p: 2 }}>
                    <Typography variant={'h6'} pb={3}>
                        Időpontozás megerősítése
                    </Typography>
                    <Typography paragraph>
                        Az időpontozás elindításával az összes manuálisan megadott adat <strong>el fog veszni</strong>. Ez a művelet az összes járat újra
                        időpontozását eredményezi.
                    </Typography>
                    <Typography paragraph>Biztosan szeretné az időpontozást végrehajtani?</Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'space-between' }}>
                    <Button
                        sx={{ mx: 1, borderRadius: 50 }}
                        variant={'contained'}
                        size={'small'}
                        color={'info'}
                        onClick={() => onClose()}
                        startIcon={<ArrowBackIosNewTwoToneIcon />}>
                        Mégsem
                    </Button>
                    <Button
                        variant='contained'
                        size={'small'}
                        sx={{ borderRadius: 50, marginRight: 0.25 }}
                        onClick={() => handleAutoTimeTable()}
                        endIcon={loading ? <CircularProgress size={20} color='inherit' /> : <ScheduleSendIcon />}>
                        Időpontozás
                    </Button>
                </CardActions>
            </ConfirmCard>
        </Modal>
    );
}
