import { Button, CardActions, CardContent, Modal, Typography } from '@mui/material';
import { ConfirmCard } from './Styles';

interface TravelDistanceWarningModalProps {
    open: boolean;
    onClose: () => void;
}
export default function TravelDistanceWarningModal({ open, onClose }: TravelDistanceWarningModalProps) {
    return (
        <Modal open={open} onClose={onClose}>
            <ConfirmCard>
                <CardContent sx={{ p: 2 }}>
                    <Typography variant={'h6'} pb={3}>
                        Hiányzó távolság adatok
                    </Typography>
                    <Typography paragraph>
                        A terv nem minden járatán van a megtett távolság kiszámítva.
                        <br />
                        Kérjük, hogy először számítsa ki a távolságot.
                    </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center' }}>
                    <Button variant='contained' size={'small'} sx={{ borderRadius: 50, px: 2 }} onClick={onClose}>
                        Bezárás
                    </Button>
                </CardActions>
            </ConfirmCard>
        </Modal>
    );
}
