import {
    Box,
    Button,
    CardActions,
    CardContent,
    List,
    ListItem,
    ListItemText,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import { useEffect, useState } from 'react';
import { useAuth, useProvideSnackBar } from '../../hooks';
import { Validation, ValidationResponse } from '../../@types/planValidation';
import { Link } from 'react-router-dom';
import { ConfirmCard } from './Styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface ScheduleWarningModalProps {
    open: boolean;
    onClose: () => void;
}

export default function ScheduleWarningModal({ open, onClose }: ScheduleWarningModalProps) {
    const { user } = useAuth();
    const [validationData, setValidationData] = useState<ValidationResponse>();
    const { showError } = useProvideSnackBar();

    const fetchValidation = async () => {
        try {
            const response = await fetch('/api/plans/validate', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
            });

            if (response.ok) {
                const validationResult: ValidationResponse = await response.json();
                setValidationData(validationResult);
            } else {
                throw new Error('Failed to fetch validation data');
            }
        } catch (error: any) {
            showError(`Hiba történt a validációs adat lekérdezése közben: ${error.message}`);
        }
    };

    useEffect(() => {
        if (open) {
            fetchValidation();
        }
    }, [open]);

    return (
        <Modal open={open} onClose={onClose}>
            <ConfirmCard>
                <CardContent sx={{ p: 2 }}>
                    <Typography variant={'h5'} pb={3} align='left'>
                        Járattal kapcsolatos problémák
                    </Typography>
                    <Box sx={{ maxHeight: '300px', minWidth: '800px', overflow: 'auto' }}>
                        <TableContainer>
                            <Table padding={'none'}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='left' sx={{minWidth: '120px'}}>
                                            <strong>Partner</strong>
                                        </TableCell>
                                        <TableCell align='left'>
                                            <strong>Menetrend</strong>
                                        </TableCell>
                                        <TableCell align='left'>
                                            <strong>Ok</strong>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(validationData?.Validation || {}).map(([planName, items]) => (
                                        <TableRow key={`${planName}-holder`}>
                                            <TableCell style={{ verticalAlign: 'top' }}>
                                                <Typography paragraph pt={1}>{items[0].Workplace}</Typography>
                                            </TableCell>
                                            <TableCell style={{ verticalAlign: 'top' }}>
                                                <Typography variant={'body1'} pt={1}>{planName}
                                                    <Link
                                                        to={`/planner?planID=${items[0].PlanID}`}
                                                        target={'_blank'}
                                                        style={{
                                                            textDecoration: 'none',
                                                            verticalAlign: 'sub',
                                                            paddingLeft: '4px',
                                                        }}>
                                                        <OpenInNewIcon fontSize={'small'} sx={{ p: 0 }} />
                                                    </Link>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                {items.map((item: Validation) => (
                                                    <List key={`${planName}-content-${item.PlanID}`} dense
                                                          disablePadding>
                                                        {item.Messages.map(m => (
                                                            <ListItem key={`${planName}-${item.PlanID}-${m}-message`}
                                                                      sx={{ margin: 0 }}
                                                                      disableGutters>
                                                                <ListItemText sx={{ margin: 0 }} primary={m} />
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                ))}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end', py: 0, my: 0 }}>
                    <Button
                        sx={{ mx: 1, borderRadius: 50 }}
                        variant={'contained'}
                        size={'small'}
                        color={'info'}
                        onClick={() => onClose()}
                        startIcon={<ArrowBackIosNewTwoToneIcon />}>
                        Bezár
                    </Button>
                </CardActions>
            </ConfirmCard>
        </Modal>
    );
}
