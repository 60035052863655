import { DirectionShiftListMap } from './requirements';

export interface Workplace {
    Name: string;
    Address: string;
    Shifts: DirectionShiftListMap;
    Longitude: number;
    Latitude: number;
    QueryName: string;
}

export interface Shift {
    Name: string;
    StartAt: string;
    ArrivalAt: string;
    DepartureAt: string;
}

export interface ScheduleParams {
    workplace: string;
    from: string;
    to: string;
}

export interface ScheduleResponse {
    RequirementID: number;
    DueAt: string;
    Workplace: string;
    Shift: string;
    Direction: string;
    ShiftArrivalAt: string;
    ShiftDepartureAt: string;
    Lines: ScheduleLine[];
}

export interface ScheduleLine {
    ID: number;
    Name: string;
    Capacity: number;
    Stops: ScheduleLineStop[];
}

export interface ScheduleLineStop {
    ID: number;
    Name: string;
    DepartureHour: number;
    DepartureMinute: number;
    Position: number;
    Passengers: ScheduleLineStopPassenger[];
}
export interface ScheduleLineStopPassenger {
    Name: string;
    WorkerIdNumber: string;
}

export enum DirectionType {
    FROM_HOME = 'munkahely',
    TO_HOME = 'haza',
}

export const DefaultWorkplace: Workplace = {
    Address: '',
    Name: 'Kérem válasszon',
    QueryName: '',
    Latitude: 0,
    Longitude: 0,
    Shifts: {
        FROM_HOME: [],
        TO_HOME: [],
    },
};

export enum WorkplaceQueryNameNameMap {
    eckerle = 'ECKERLE',
    cabtec = 'CabTec',
    odwelektrik = 'ODW Elektrik',
    pcapostag = 'P&C Apostag',
    honsa = 'HONSA',
    pex = 'PEX',
    obo = 'OBO',
    prettlérd = 'Prettl Érd',
    jabil = 'Jabil',
    segahungarykft = 'S.E.G.A Hungary Kft',
    boschmaklár = 'Bosch Maklár',
    lenovo = 'LENOVO',
    eurostylesystem = 'Eurostyle System',
    harman = 'Harman',
    hanon = 'Hanon',
    bonafarm = 'Bonafarm',
    seiren = 'Seiren',
    mcsvágóhíd = 'MCS Vágóhíd',
    csingilig = 'Csingilig',
    bayer = 'Bayer',
    samsichungarykft = 'Samsic Hungary KFT',
    flextronics = 'Flextronics',
    glraktárlenovo = 'GL Raktár Lenovo',
}

export const WorkplaceNameQueryNameMap = {
    ECKERLE: 'eckerle',
    CabTec: 'cabtec',
    'ODW Elektrik': 'odwelektrik',
    'P&C Apostag': 'pcapostag',
    HONSA: 'honsa',
    PEX: 'pex',
    OBO: 'obo',
    'Prettl Érd': 'prettlérd',
    Jabil: 'jabil',
    'S.E.G.A Hungary Kft': 'segahungarykft',
    'Bosch Maklár': 'boschmaklár',
    LENOVO: 'lenovo',
    'Eurostyle System': 'eurostylesystem',
    Harman: 'harman',
    Hanon: 'hanon',
    Bonafarm: 'bonafarm',
    Seiren: 'seiren',
    'MCS Vágóhíd': 'mcsvágóhíd',
    Csingilig: 'csingilig',
    Bayer: 'bayer',
    'Samsic Hungary KFT': 'samsichungarykft',
    Flextronics: 'flextronics',
    'GL Raktár Lenovo': 'glraktárlenovo',
};
