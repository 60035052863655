import { Button, CardActions, CardContent, CircularProgress, Modal, Typography } from '@mui/material';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { useAuth, useProvideSnackBar } from '../../utils';
import { useState } from 'react';
import { ConfirmCard } from './Styles';

interface SyncConfirmModalProps {
    open: boolean;
    afterSyncCallback: () => void;
    onClose: () => void;
    planID: number | undefined;
    setMobileAppSyncAt: (mobileAppSyncAt: Date) => void;
}

export default function SyncConfirmModal({ open, onClose, afterSyncCallback, planID, setMobileAppSyncAt }: SyncConfirmModalProps) {
    const { user } = useAuth();
    const { showError, showSuccess, showResponseError } = useProvideSnackBar();
    const [loading, setLoading] = useState(false);

    const handleSync = async () => {
        if (loading) return;
        if (!planID) {
            showError(`Hiba: Hiányzó menetrend`);
            return;
        }
        setLoading(true);
        try {
            const response = await fetch('/api/plans/publish', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    PlanID: planID,
                    RunMobileAppSync: true,
                    RunEmailDelivery: false,
                }),
            });
            if (response.ok) {
                showSuccess(`A Buszom app-ot sikeresen frissítettük`);
                setMobileAppSyncAt(new Date());
                afterSyncCallback();
                return;
            }
            showResponseError(response);
        } catch (error: any) {
            showError('Hiba történt a menetrend szinkronizálása közben');
        } finally {
            setLoading(false);
            onClose();
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <ConfirmCard>
                <CardContent sx={{ p: 2 }}>
                    <Typography variant={'h6'} pb={3}>
                        Menetrend szinkronizálása
                    </Typography>
                    <Typography paragraph>
                        A <strong>SZINKRONIZÁLÁS</strong> gombra kattintva a menetrendet szinkronizáljuk a Buszom app-pal.
                    </Typography>
                    <Typography paragraph>Tényleg szinkronizálni szeretné a menetrendet?</Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'space-between' }}>
                    <Button
                        sx={{ mx: 1, borderRadius: 50 }}
                        variant={'contained'}
                        size={'small'}
                        color={'info'}
                        onClick={() => onClose()}
                        startIcon={<ArrowBackIosNewTwoToneIcon />}>
                        mégsem
                    </Button>
                    <Button
                        variant='contained'
                        size={'small'}
                        sx={{ borderRadius: 50, marginRight: 0.25 }}
                        onClick={() => handleSync()}
                        endIcon={loading ? <CircularProgress size={20} color='inherit' /> : <CloudSyncIcon />}>
                        Szinkronizálás
                    </Button>
                </CardActions>
            </ConfirmCard>
        </Modal>
    );
}
