import { Card, styled } from '@mui/material';

export const ConfirmCard = styled(Card)`
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    min-width: 400px;
    background-color: white;
    box-shadow: 2px 2px;
    padding-bottom: 14px;

    @media (max-width: 900px) {
        position: absolute;
        top: 100px;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translate(0%, 0%);
        width: 100%;
    }
`;
