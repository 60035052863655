import { Box, Checkbox } from '@mui/material';
import React from 'react';
import { DragIndicator } from '@mui/icons-material';
import { useDraggable } from '@dnd-kit/core';
import { Stop } from '../../@types/requirements';
import { useRequirementStopSelectionContext } from '../../hooks/useRequirementStopSelectionContext';

type RequirementItemProps = {
    stop: Stop;
};

const RequirementItem = ({ stop }: RequirementItemProps) => {
    const { stopSelectionStatus, setStopSelectionStatus } = useRequirementStopSelectionContext();
    const { attributes, listeners, setNodeRef } = useDraggable({
        id: stop.Name + stop.ID,
        data: {
            stop: stop,
        },
    });

    return (
        <Box className='requirement-item-cell' ref={setNodeRef} {...attributes} id={stop.Name} sx={{ display: 'flex', alignItems: 'center' }}>
            <DragIndicator
                {...listeners}
                sx={{
                    verticalAlign: 'middle',
                    cursor: 'grab',
                }}
            />
            <Checkbox
                key={stop.Name}
                sx={{ paddingLeft: 0 }}
                checked={stopSelectionStatus[stop.ID] || false}
                onChange={event => {
                    setStopSelectionStatus({
                        ...stopSelectionStatus,
                        [stop.ID]: event.target.checked,
                    });
                }}
            />
        </Box>
    );
};

export { RequirementItem };
