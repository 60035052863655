import React from 'react';
import {Box, Button, Grid, Paper, Typography} from "@mui/material";
import {ArrowForwardIosTwoTone} from "@mui/icons-material";
import {Link} from 'react-router-dom';
import {PageHeader} from '../Layout/styles';

const LOGIN_ENDPOINT = (window as any).REACT_APP_AUTH_URL || "/";


const LoginPaper = () => {
    return <Box sx={{m: 0, p: 0}}>
        <PageHeader px={5}>
            <Link to="/">
                <img src="/images/br_logo_horizontal.svg" alt="BuszRent logo"/>
            </Link>
        </PageHeader>
        <Grid container sx={{minHeight: '80vh'}}>
            <Paper sx={{
                width: 'fitContent',
                display: 'inline-block',
                p: 3,
                margin: 'auto'
            }}>
                <Typography variant={"h4"}>Kilépés</Typography>
                <Typography paragraph>A munkamenet lejárt.</Typography>
                <Button
                    sx={{mx: 1, borderRadius: 50}}
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => window.location.assign(`${LOGIN_ENDPOINT}?redirect_to=${encodeURIComponent(window.location.href)}`)}
                    endIcon={<ArrowForwardIosTwoTone/>}
                >Tovább a bejelentkezéshez </Button>
            </Paper>
        </Grid>
    </Box>
}

export {LoginPaper}