import { Box, Card, Paper, styled } from '@mui/material';

export const PageHeader = styled(Box)`
    background-image: url('/images/header-background.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    width: 100%;
`;

export const Greeter = styled(Box)`
    color: white;
    display: flex;
    align-items: center;
`;

export const StyledMainPaper = styled(Paper)`
    height: calc(100vh - 150px);
    max-height: 100vh;
    padding: 0;
    margin: 0;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
`;

export const StyledDetailsCard = styled(Card)`
    height: fit-content;
    width: 100%;
    flex-direction: column;
    display: flex;
    box-shadow: none;
    overeflow: auto;
`;

export const StyledDateRangeCard = styled(Card)`
    height: fit-content;
    width: auto;
    display: flex;
    box-shadow: none;
    justify-content: center;
    padding: 0 10px;
    margin: 20px 0 20px 0;
`;
