const leftPad = (number: number) => {
    return number < 10 ? '0' + number : number.toString();
};

const formatDateToYyyyMMdd = (date: Date | null) => {
    if (!date) return '';
    const month = leftPad(date.getMonth() + 1);
    const dayOfMonth = leftPad(date.getDate());
    return `${date.getFullYear()}-${month}-${dayOfMonth}`;
};

const formatDateToYyyyMMddhhmmss = (date: Date | null) => {
    if (!date) return '';
    const month = leftPad(date.getMonth() + 1);
    const dayOfMonth = leftPad(date.getDate());
    return `${date.getFullYear()}-${month}-${dayOfMonth} 00:00:00`;
};

const getDaysArray = (from: Date, to: Date): Date[] => {
    const days: Date[] = [];
    let counter = 0;

    for (let day = new Date(from); day.getTime() <= to.getTime() && counter < 7; day.setDate(day.getDate() + 1), counter++) {
        days.push(new Date(day));
    }
    return days;
};

export { formatDateToYyyyMMdd, formatDateToYyyyMMddhhmmss, getDaysArray };
