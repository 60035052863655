import { Requirement, StopMaxNPassengers } from '../@types/requirements';

export const calculateMaxNPassengers = (requirements: Requirement[]) => {
    let maxNPassengers: StopMaxNPassengers = {};
    requirements.forEach(req => {
        req.Stops.forEach(stop => {
            maxNPassengers[stop.CrmID] = Math.max(maxNPassengers[stop.CrmID] || 0, stop.NPassengers);
        });
    });
    return maxNPassengers;
};
