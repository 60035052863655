import { Button, CircularProgress, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { memo, useEffect, useMemo, useState } from 'react';
import { BusType, BusTypes, DailyPassengers, Line, SortableLineStop } from '../../@types/lines';
import { DirectionType, Requirement } from '../../@types/requirements';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth, useProvideSnackBar } from '../../hooks';
import Checkbox from '@mui/material/Checkbox';
import { LineIsOnMapState } from '../../@types/map';
import MapIcon from '@mui/icons-material/Map';
import SortIcon from '@mui/icons-material/Sort';
import StraightenIcon from '@mui/icons-material/Straighten';
import { Workplace } from '../../@types/workplace';
import TimerIcon from '@mui/icons-material/Timer';
import { AddRoad, RemoveRoad } from '@mui/icons-material';

interface LinePropertiesProps {
    requirements: Requirement[];
    line: Line;
    ids: Array<number>;
    onDeleteLineClick: () => void;
    dailyPassengers: DailyPassengers;
    afterChangeCallback: () => void;
    linesForMap: LineIsOnMapState;
    setLinesForMap: React.Dispatch<React.SetStateAction<LineIsOnMapState>>;
    lineStops: SortableLineStop[];
    workplace: Workplace;
    onSynced: boolean;
    direction: keyof typeof DirectionType;
}

const getBusTypeByCapacity = (capacity: string | number) => {
    if (!capacity) {
        return BusTypes[0];
    }
    for (const bt of BusTypes) {
        if (Number(capacity) <= bt.capacity) return bt;
    }
    return BusTypes[BusTypes.length - 1];
};

const LineProperties = memo(function ({
    requirements,
    line,
    ids,
    onDeleteLineClick,
    dailyPassengers,
    afterChangeCallback,
    linesForMap,
    setLinesForMap,
    lineStops,
    workplace,
    direction,
}: LinePropertiesProps) {
    const { user } = useAuth();
    const { showError, showResponseError, showSuccess } = useProvideSnackBar();

    const [busType, setBusType] = useState<BusType>(getBusTypeByCapacity(line.Capacity));
    const [overloaded, setOverloaded] = useState<boolean>(false);
    const [maxPassengers, setMaxPassengers] = useState<number>(0);
    const [name, setName] = useState<string>(line.Name);
    const [capacity, setCapacity] = useState<number | string>(line.Capacity || BusTypes[BusTypes.length - 1].capacity);
    const [useHighways, setUseHighways] = useState<boolean>(line.UseHighways);
    const [travelDistanceLoading, setTravelDistanceLoading] = useState<boolean>(false);
    const [autoSortLoading, setAutoSortLoading] = useState<boolean>(false);

    useEffect(() => {
        setMaxPassengers(Math.max(...Object.values(dailyPassengers)));
    }, [dailyPassengers]);

    useEffect(() => {
        setBusType(getBusTypeByCapacity(line.Capacity));
    }, [requirements, line]);

    useEffect(() => {
        if (busType && maxPassengers > busType.capacity) {
            showError('Sok jó ember kis helyen is elfér, de ezen a buszon nem biztosan');
        }
    }, [busType, maxPassengers]);

    useEffect(() => {
        setOverloaded(maxPassengers > (line.Capacity || 0));
    }, [requirements, line, maxPassengers]);

    useEffect(() => {
        if (name !== line.Name) {
            const timer = setTimeout(() => {
                editLine(name, busType, capacity, null);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [name]);

    useEffect(() => {
        if (capacity === undefined || capacity === line.Capacity) {
            return;
        }
        const timer = setTimeout(() => {
            editLine(name, busType, capacity, null);
        }, 2000);
        return () => clearTimeout(timer);
    }, [capacity]);

    const editLine = useMemo(
        () => async (updatedName: string, updatedBusType?: BusType, customCapacity?: number | string, updatedUseHighways?: boolean | null) => {
            if (!updatedName || updatedBusType === undefined) {
                return;
            }

            const updatedCapacity = customCapacity || updatedBusType?.capacity;

            const response = await fetch('/api/lines', {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    Lines: requirements.map((item: Requirement, i: number) => {
                        return {
                            ID: ids[i],
                            RequirementID: item.ID,
                            Name: updatedName,
                            Capacity: updatedCapacity,
                            UseHighways: updatedUseHighways,
                        };
                    }),
                }),
            });

            if (!response.ok) {
                showResponseError(response);
                return;
            }
            showSuccess(`Sikeres járat módosítás`);
            afterChangeCallback();
        },
        []
    );

    const onAutoPlanStops = async () => {
        const response = await fetch('/api/lines/auto_plan', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.accessToken}`,
            },
            body: JSON.stringify({
                LineIDs: requirements.map((item: Requirement, i: number) => ids[i]),
                PlanID: requirements[0].PlanID,
            }),
        });
        if (!response.ok) {
            showResponseError(response);
            return;
        }
        afterChangeCallback();
    };

    const onAutoSort = async () => {
        setAutoSortLoading(true);
        try {
            const response = await fetch('/api/lines/auto_sort', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    LineIDs: requirements.map((item: Requirement, i: number) => ids[i]),
                    PlanID: requirements[0].PlanID,
                    Algorithm: 'astar',
                }),
            });
            if (!response.ok) {
                showResponseError(response);
                return;
            }
            afterChangeCallback();
        } catch (error: any) {
            showError('Hiba történt: ' + error.message);
        } finally {
            setAutoSortLoading(false);
        }
    };

    const onAutoTimeTable = async () => {
        const response = await fetch('/api/lines/auto_timetable', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.accessToken}`,
            },
            body: JSON.stringify({
                LineIDs: requirements.map((item: Requirement, i: number) => ids[i]),
                PlanID: requirements[0].PlanID,
            }),
        });
        if (!response.ok) {
            showResponseError(response);
            return;
        }
        afterChangeCallback();
    };

    const onUpdateTravelDistance = async () => {
        setTravelDistanceLoading(true);
        try {
            const response = await fetch('/api/lines/update_travel_distance', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    LineIDs: requirements.map((item: Requirement, i: number) => ids[i]),
                }),
            });
            if (!response.ok) {
                showResponseError(response);
                return;
            }
            showSuccess('Sikeres megtett távolság frissítés');
            afterChangeCallback();
        } catch (error: any) {
            showError('Hiba történt: ' + error.message);
        } finally {
            setTravelDistanceLoading(false);
        }
    };

    const getGoogleMapsLink = () => {
        let baseURL = `https://www.google.com/maps/dir/?api=1&travelmode=bus`;
        let lineStopEnd = 0;
        let lineStopStart = 0;

        if (direction === 'FROM_HOME') {
            baseURL += `&origin=${lineStops[0].Lat},${lineStops[0].Lon}&destination=${workplace.Latitude},${workplace.Longitude}`;
            lineStopStart = 1;
            lineStopEnd = lineStops.length;
        } else {
            baseURL += `&origin=${workplace.Latitude},${workplace.Longitude}&destination=${lineStops.at(-1)?.Lat},${lineStops.at(-1)?.Lon}`;
            lineStopStart = 0;
            lineStopEnd = lineStops.length - 1;
        }

        return (
            baseURL +
            (lineStops.length > 1
                ? `&waypoints=${lineStops
                      .slice(lineStopStart, lineStopEnd)
                      .map(s => `${s.Lat},${s.Lon}`)
                      .join('|')}`
                : '')
        );
    };

    return (
        <Grid container p={1} m={0} spacing={0} sx={{ alignItems: 'end', backgroundColor: overloaded ? '#fdeded' : '#efefef' }}>
            <Grid item sm={'auto'}>
                <Checkbox
                    checked={linesForMap[line.Name]}
                    onChange={event => {
                        const newLinesForMap = {
                            ...linesForMap,
                            [line.Name]: event.target.checked,
                        };
                        setLinesForMap(newLinesForMap);
                    }}
                    sx={{ paddingLeft: '0px' }}
                />
            </Grid>

            <Grid item xs={12} sm={'auto'}>
                <TextField label='Járat neve' variant='standard' value={name} size={'small'} sx={{ width: '150px' }} onChange={e => setName(e.target.value)} />
            </Grid>

            <Grid item xs={12} sm={'auto'} px={1}>
                <FormControl size={'small'}>
                    <InputLabel id='workplace-label' variant='standard'>
                        Busz
                    </InputLabel>
                    <Select
                        variant='standard'
                        value={busType?.value}
                        onChange={e => {
                            const type = BusTypes.find(type => type.value === e.target.value);
                            setBusType(type || BusTypes[0]);
                            setCapacity((type || BusTypes[0]).capacity);
                        }}>
                        {BusTypes.map(busType => (
                            <MenuItem key={busType.value} value={busType.value}>
                                {`${busType.shortTitle} (${busType.capacity} fő)`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={'auto'}>
                <FormControl>
                    <TextField
                        label='Létszám'
                        variant='standard'
                        value={capacity}
                        onChange={e => {
                            const newCapacity = Number(e.target.value);
                            setCapacity(!e.target.value ? '' : newCapacity);
                            const busTypeFromCapacity = getBusTypeByCapacity(e.target.value);
                            setBusType(busTypeFromCapacity);
                        }}
                        sx={{ width: '3.2em' }}
                        size={'small'}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>fő</InputAdornment>,
                            inputProps: {
                                min: 0,
                            },
                        }}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={'auto'} alignSelf={'flex-end'} ml={'auto'} sx={{ textAlign: 'right' }} pr={0.5} pb={0.5}>
                <Button
                    variant={'contained'}
                    color={useHighways ? 'success' : 'error'}
                    startIcon={useHighways ? <AddRoad /> : <RemoveRoad />}
                    title={`A járat ${useHighways ? '' : 'nem '}használhat autópályát`}
                    size='small'
                    onClick={() => {
                        setUseHighways(!useHighways);
                        editLine(name, busType, capacity, !useHighways).then(() => onUpdateTravelDistance());
                    }}
                    sx={{
                        cursor: 'pointer',
                        borderRadius: '50px',
                        minWidth: 0,
                        padding: 0.5,
                        textAlign: 'center',
                        margin: 0,
                        mr: 0.5,
                        '.MuiButton-startIcon': { margin: 0 },
                    }}
                />
                <Button
                    variant='contained'
                    color='info'
                    startIcon={
                        autoSortLoading ? <CircularProgress size={20} color='inherit' /> : <SortIcon sx={{ margin: '0!important', span: { margin: 0 } }} />
                    }
                    title={'Rendezés'}
                    onClick={() => onAutoSort()}
                    size={'small'}
                    sx={{
                        cursor: 'pointer',
                        borderRadius: '50px',
                        minWidth: 0,
                        padding: 0.5,
                        textAlign: 'center',
                        margin: 0,
                        mr: 0.5,
                        '.MuiButton-startIcon': { margin: 0 },
                    }}
                />

                <Button
                    variant='contained'
                    color='info'
                    startIcon={<TimerIcon sx={{ margin: '0!important', span: { margin: 0 } }} />}
                    title={'Időpontozó'}
                    onClick={() => onAutoTimeTable()}
                    size={'small'}
                    sx={{
                        cursor: 'pointer',
                        borderRadius: '50px',
                        minWidth: 0,
                        padding: 0.5,
                        textAlign: 'center',
                        margin: 0,
                        mr: 0.5,
                        '.MuiButton-startIcon': { margin: 0 },
                    }}
                />

                <Button
                    variant='contained'
                    color='info'
                    startIcon={
                        travelDistanceLoading ? (
                            <CircularProgress size={20} color='inherit' />
                        ) : (
                            <StraightenIcon sx={{ margin: '0!important', span: { margin: 0 } }} />
                        )
                    }
                    title={'Megtett távolság frissítés'}
                    onClick={() => onUpdateTravelDistance()}
                    size={'small'}
                    sx={{
                        cursor: 'pointer',
                        borderRadius: '50px',
                        minWidth: 0,
                        padding: 0.5,
                        textAlign: 'center',
                        margin: 0,
                        mr: 0.5,
                        '.MuiButton-startIcon': { margin: 0 },
                    }}
                />

                {lineStops.length > 0 && (
                    <Button
                        variant='contained'
                        color='primary'
                        startIcon={<MapIcon sx={{ margin: '0!important', span: { margin: 0 } }} />}
                        title={'Térkép'}
                        href={getGoogleMapsLink()}
                        target={'_blank'}
                        size={'small'}
                        sx={{
                            cursor: 'pointer',
                            borderRadius: '50px',
                            minWidth: 0,
                            padding: 0.5,
                            textAlign: 'center',
                            margin: 0,
                            mr: 0.5,
                            '.MuiButton-startIcon': { margin: 0 },
                        }}
                    />
                )}

                <Button
                    variant='contained'
                    color='error'
                    startIcon={<DeleteIcon sx={{ margin: '0!important', span: { margin: 0 } }} />}
                    title={'Járat törlése'}
                    onClick={() => onDeleteLineClick()}
                    size={'small'}
                    sx={{
                        cursor: 'pointer',
                        borderRadius: '50px',
                        minWidth: 0,
                        padding: 0.5,
                        textAlign: 'center',
                        mr: 0.5,
                        '.MuiButton-startIcon': { margin: 0 },
                    }}
                />
            </Grid>
        </Grid>
    );
});

export { LineProperties };
