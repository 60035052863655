import { Button, CardActions, CardContent, Modal, Typography } from '@mui/material';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import { ConfirmCard } from './Styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface UnsyncedChangesWarningModalProps {
    open: boolean;
    onClose: () => void;
    onMove: () => void;
}
export default function UnsyncedChangesWarningModal({ open, onClose, onMove }: UnsyncedChangesWarningModalProps) {
    return (
        <Modal open={open} onClose={onClose}>
            <ConfirmCard>
                <CardContent sx={{ p: 2 }}>
                    <Typography variant={'h6'} pb={3}>
                        Nem szinkronizált változás
                    </Typography>
                    <Typography paragraph>
                        A menetrend nem lett szinkronizálva.<br/>Biztosan elnavigál az oldalról szinkronizálás nélkül?
                    </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'space-between'}}>
                    <Button
                        sx={{ borderRadius: 50 }}
                        variant={'contained'}
                        size={'small'}
                        color={'info'}
                        onClick={() => onClose()}
                        startIcon={<ArrowBackIosNewTwoToneIcon />}>
                        mégsem
                    </Button>
                    <Button variant='contained' size={'small'} sx={{ borderRadius: 50 }} onClick={onMove} endIcon={<ArrowForwardIosIcon />}>
                        tovább
                    </Button>
                </CardActions>
            </ConfirmCard>
        </Modal>
    );
}
