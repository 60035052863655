import { Circle, MapContainer, Polyline, Popup, TileLayer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useEffect } from 'react';
import { TraceItem } from './Matrix';
import { decode } from '@googlemaps/polyline-codec';

const MatrixMap = ({ items, path }: { items: TraceItem[]; path: string }) => {
    function FlyMapTo() {
        const map = useMap();

        useEffect(() => {
            if (items.length === 0) {
                return;
            }

            map.invalidateSize();
            map.flyTo([items[0].StartLat, items[0].StartLon]);
        }, [items]);

        return null;
    }

    const pathPoints = decode(path, 6);
    const firstPoint = pathPoints[0];
    const lastPoint = pathPoints[pathPoints.length - 1];

    return (
        <MapContainer center={[items[0].StartLat, items[0].StartLon]} zoom={10} scrollWheelZoom={true} style={{ height: '100%', width: '100%' }}>
            <FlyMapTo />

            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />

            {items.map((item: TraceItem) => (
                <Polyline
                    key={`${item.Key}`}
                    positions={[
                        [item.StartLat, item.StartLon],
                        [item.EndLat, item.EndLon],
                    ]}
                    pathOptions={{ opacity: 0, weight: 10, stroke: true }}>
                    <Popup>{`WayID: ${item.WayID}, Sebesség: ${item.Speed}`}</Popup>
                </Polyline>
            ))}

            {items.map((item: TraceItem) => (
                <Circle key={`${item.Key}`} radius={1} opacity={0.5} center={[item.EndLat, item.EndLon]} color='white'>
                    <Popup>{`WayID: ${item.WayID}, Sebesség: ${item.Speed}`}</Popup>
                </Circle>
            ))}

            <Polyline positions={pathPoints} pathOptions={{ color: 'red', opacity: 1, weight: 4, stroke: true }} interactive={false}></Polyline>

            <Circle radius={15} center={[firstPoint[0], firstPoint[1]]} color='yellow'>
                <Popup>Kezdet</Popup>
            </Circle>

            <Circle radius={15} center={[lastPoint[0], lastPoint[1]]} color='purple'>
                <Popup>Vég</Popup>
            </Circle>
        </MapContainer>
    );
};

export { MatrixMap };
