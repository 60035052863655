import React, { forwardRef } from 'react';
import { LineStop } from '../../@types/lines';
import { Requirement } from '../../@types/requirements';
import { Grid } from '@mui/material';

type SortableLineProps = {
    stop?: LineStop;
    requirements?: Requirement[];
    onDeleteLineStop?: (stop: LineStop) => void;
};

type Props = React.HTMLProps<HTMLDivElement>;

const SortableItem = forwardRef<HTMLDivElement | null, Props & SortableLineProps>((props: any, ref) => {
    return (
        <Grid {...props} ref={ref}>
            {props.children || <Grid>{props.id}</Grid>}
        </Grid>
    );
});

export { SortableItem };
