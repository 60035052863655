import { Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth, useProvideSnackBar } from '../../hooks';
import InputAdornment from '@mui/material/InputAdornment';

interface TravelTimeProps {
    initialTravelTime: number;
    planID: number | undefined;
    onUpdate: () => void;
}

export default function TravelTime({ initialTravelTime, planID, onUpdate }: TravelTimeProps) {
    const { showError, showSuccess, showResponseError } = useProvideSnackBar();
    const [travelTime, setTravelTime] = useState<number>(initialTravelTime);
    const [loading, setLoading] = useState<boolean>(false);
    const { user } = useAuth();

    const updateTravelTime = async (newTravelTime: number) => {
        if (loading || !planID || initialTravelTime === newTravelTime) return;

        if (newTravelTime <= 0) {
            showError(`Az utazási idő nem lehet 0 vagy negatív`);
            return;
        }
        setLoading(true);
        try {
            const response = await fetch('/api/requirements/travel_time', {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    planID: planID,
                    travelTime: newTravelTime,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                setTravelTime(data.travelTime);
                showSuccess(`Az utazási időt ${data.travelTime} percre frissítettük`);
                onUpdate();
            }
            showResponseError(response);
        } catch (error: any) {
            showError(`Hiba történt az utazási idő frissítése közben: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setTravelTime(initialTravelTime);
    }, [initialTravelTime]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            updateTravelTime(travelTime);
        }, 1000);

        return () => clearTimeout(timeout);
    }, [travelTime]);

    return (
        <Grid item xs={12} sm={4} md={'auto'}>
            <TextField
                sx={{ width: '6rem' }}
                label='Utazási idő'
                variant='standard'
                value={travelTime}
                InputProps={{
                    endAdornment: <InputAdornment position='end'>perc</InputAdornment>,
                }}
                onChange={e => {
                    const newTravelTime = Number(e.target.value);
                    setTravelTime(newTravelTime);
                }}
            />
        </Grid>
    );
}
